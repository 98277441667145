import { Divider, Form, message } from "antd";
import { useState } from "react";

import { InputNumber } from "../../../../components/form-fields/input-number";
import { api } from "../../../../api";
import { getApiResponseMsg, getApiErrorMsg } from "../../../../utils/object-util";
import { ReactComponent as Add } from "../../../../assets/images/plus-input-icon.svg";
import { ReactComponent as Substract } from "../../../../assets/images/minus-input-icon.svg";
import CustomButton from "../../../../components/button";

import styles from "../goals.module.scss";

interface Goal {
	id?: string;
	title: string;
	description: string;
	reasons: string[];
	trackingInfo: string;
	trackingButtonLabel: string;
	trackingCancelLabel: string;
	amount?: number;
	type: string;
}

interface GoalDetailsProps {
	goal: Goal;
	onClose?: () => void;
}

const GoalDetails = ({ goal, onClose }: GoalDetailsProps) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [targetValue, setTargetValue] = useState<number>(goal.amount ? goal.amount : 0);

	const handleAdd = () => {
		const updatedValue = Math.min(targetValue + 1, 7);
		setTargetValue(updatedValue);
		form.setFieldsValue({ [getFieldName()]: updatedValue });
	};

	const handleRemove = () => {
		const updatedValue = Math.max(targetValue - 1, 0);
		setTargetValue(updatedValue);
		form.setFieldsValue({ [getFieldName()]: updatedValue });
	};

	const getFieldName = () => {
		return goal.title.toLowerCase().replace(/\s/g, "");
	};

	const handleTracking = () => {
		setLoading(true);
		api
			.put({
				path: `/goals/${goal.id}`,
				formdata: { amount: targetValue },
				service: "job",
			})
			.then((response) => {
				message.success({
					content: getApiResponseMsg(response),
					key: "success",
					duration: 3,
				});
				if (onClose) onClose();
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
			})
			.finally(() => {
				setLoading(false);
				if (onclose) onClose;
			});
	};

	return (
		<>
			<div className={styles["wrapper-top"]}>
				<p className={styles["title"]}>{goal.title}</p>
				<p className={styles["content"]}>{goal.description}</p>
				<p className={styles["title"]}>Why {goal.type} Matters</p>
				<ul className={styles["content"]}>
					{goal.reasons.map((reason: any, index: any) => (
						<li key={index} className={styles["pointers"]}>
							<span className={styles["subtitle"]}>{reason.split(":")[0]}:</span>{" "}
							{reason.split(":")[1]}
						</li>
					))}
				</ul>
			</div>
			<Divider />
			<div className={styles["wrapper-content"]}>
				<p className={styles["title"]}>Set Your Goal:</p>
				<p className={styles["subtitle"]}>
					{goal.title === "Water Goals" ? "Daily Water Intake (liters)" : "Choose your target"}
				</p>
				<p className={styles["content"]}>Drag the slider to set your target:</p>
				<Form form={form} initialValues={{ [getFieldName()]: goal.amount }}>
					<Form.Item name={[getFieldName()]}>
						<InputNumber
							type="number"
							min={0}
							max={7}
							className={styles["input-number"]}
							value={targetValue}
							addonBefore={<CustomButton icon={<Substract />} type="link" onClick={handleRemove} />}
							addonAfter={<CustomButton icon={<Add />} type="link" onClick={handleAdd} />}
						/>
					</Form.Item>
				</Form>
			</div>
			<Divider />
			<div className={styles["wrapper-bottom"]}>
				<p className={styles["title"]}>Tracking</p>
				<p className={styles["subtitle"]}>
					{goal.title === "Water Goals"
						? "Water Intake Tracker"
						: goal.title === "Distance Goals"
							? "Wearable Tracking"
							: "Activity Log"}
				</p>
				<p className={styles["content"]}>{goal.trackingInfo}</p>
				<div className={styles["button-wrapper"]}>
					<CustomButton type="default" className={styles["button"]} onClick={onClose}>
						{goal.trackingCancelLabel}
					</CustomButton>
					<CustomButton
						type="primary"
						className={styles["button"]}
						onClick={handleTracking}
						loading={loading}
					>
						{goal.trackingButtonLabel}
					</CustomButton>
				</div>
			</div>
		</>
	);
};
export default GoalDetails;
