import PlaceHolderScreenIcon from "../../assets/images/placeholder-screen.svg";

import styles from "./defaultPlaceHolderScreen.module.scss";

const DefaultPlaceHolderScreen = () => {
  return (
    <div className={styles["main-wrapper"]}>
      <img src={PlaceHolderScreenIcon} alt="placeholder-screen" />
    </div>
  );
};

export default DefaultPlaceHolderScreen;
