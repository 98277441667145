import { useEffect, useState } from "react";
import { message } from "antd";

import Header from "../../../../../components/header";
import { ITeamMemberData } from "../../../../../pages/team/team-member/team-member-profile";
import { api } from "../../../../../api";
import { getApiErrorMsg } from "../../../../../utils/object-util";
import { isNotEmptyArray } from "../../../../../utils/type-util";
import Spinner from "../../../../../components/spinner";
import Empty from "../../../../../components/empty";

import LeaderBoardCard from "./squad-leaderboard-card";
import styles from "./squadLeaderboard.module.scss";

export interface ISquadData {
	id: string;
	name: string;
	tenant_id: string;
	score: number;
	team_members: ITeamMemberData[];
}

const SquadLeaderboard = () => {
	const [fetchingSquads, setFetchingSquads] = useState(true);

	const [squadData, setSquadData] = useState<ISquadData[]>([]);

	const fetchSquads = () => {
		setFetchingSquads(true);
		api
			.post({
				path: "/squads/all",
				service: "job",
				formdata: {
					orderBy: "score",
					order: "desc",
				},
			})
			.then((response: any) => setSquadData(response?.data?.data?.squads || []))
			.catch((err: any) => {
				message.error({
					content: getApiErrorMsg(err),
					key: "squad-list",
					duration: 3,
				});
			})
			.finally(() => setFetchingSquads(false));
	};

	useEffect(() => {
		fetchSquads();
	}, []);

	return (
		<div className={styles["leaderboard-wrapper"]}>
			<Header title="Squad Leaderboard" backIcon/>
			{fetchingSquads ? (
				<Spinner />
			) : (
				<div className={styles["leaderboard-wrapper"]}>
					{isNotEmptyArray(squadData) ? (
						squadData.map((squad, index) => (
							<LeaderBoardCard key={squad.id} squad={squad} squadIndex={index} />
						))
					) : (
						<Empty />
					)}
				</div>
			)}
		</div>
	);
};

export default SquadLeaderboard;
