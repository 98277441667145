import { useRealTime } from "../../hooks";

interface IProps {
	className?: string;
	style?: React.CSSProperties;
	type?: "days" | "hours" | "minutes" | "seconds" | "milliseconds";
	format: string;
}

const CurrentTime = (props: IProps) => {
	const { className, style, type = "seconds", format } = props;
	const { currentTime } = useRealTime(type);

	return (
		<span className={`${className ?? ""}`} style={style ?? {}}>
			{currentTime.format(format)}
		</span>
	);
};

export default CurrentTime;
