import React from "react";
import { BaseCard } from "../../../components/base-card";
import CustomButton from "../../../components/button";
import WaterDropProgress from "../waterprogress";
import { useCurrentUser } from "../../../hooks";

import styles from "./water-log-card.module.scss";

interface IProps {
	waterConsumed: number;
	waterConsumedGoal: number;
	onClick: () => void;
}

const WaterLogCard: React.FC<IProps> = ({ waterConsumed, waterConsumedGoal, onClick }) => {
	const { user } = useCurrentUser();
	return (
		<BaseCard
			footer={
				<CustomButton
					type="link"
					className={styles["waterlog-button"]}
					onClick={user?.sessions_data.health?.fitbit_integrated ? onClick : () => { }}
					disabled={!user?.sessions_data.health?.fitbit_integrated}
				>
					Log Water
				</CustomButton>
			}
			wrapperClassName={styles["week"]}
		>
			<div className={styles["wrapper"]}>
				<div className={styles["water-progress-wrapper"]}>
					<p className={styles["water-progress-title"]}>Did you drink enough water today?</p>
					<p className={styles["progress-text"]}>
						{waterConsumed > 0 ? waterConsumed?.toFixed(2) : 0} ml
						<span className={styles["progress-subtext"]}>
							{`(${
								waterConsumedGoal > 0 && waterConsumedGoal - waterConsumed > 0
									? waterConsumedGoal - waterConsumed
									: 0
							} ml left)`}
						</span>
					</p>
				</div>
				<WaterDropProgress width={70} height={83} progress={waterConsumed / waterConsumedGoal} />
			</div>
		</BaseCard>
	);
};

export default WaterLogCard;
