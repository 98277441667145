import { createRef, useEffect, useState } from "react";
import { Modal as AntdModal, ModalProps } from "antd";

import closeIcon from "../../assets/images/close-icon.svg";

import styles from "./modal.module.scss";
import CustomButton from "../button";

interface HeaderProps {
	title: string | React.ReactNode;
	headerRightContent?: string | React.ReactNode;
	onCancel: () => void;
	centerContent?: string | React.ReactNode;
	headerClassName?: string;
}

interface HeaderComponentProps extends HeaderProps {
	setHeaderHeight: (val: number) => void;
	isModalClosable?: boolean;
}

interface IProps extends Omit<ModalProps, "title" | "onCancel">, HeaderProps {
	isOpen: boolean;
	children: string | React.ReactNode;
	bodyStyle?: React.CSSProperties;
	centerContent?: string | React.ReactNode;
	headerClassName?: string;
	closable?: boolean;
}

const Modal = ({
	title,
	isOpen,
	onCancel,
	children,
	headerRightContent,
	centerContent,
	bodyStyle,
	headerClassName,
	className,
	closable = true,
	...rest
}: IProps) => {
	const [headerHeight, setHeaderHeight] = useState(0);

	useEffect(() => {
		if (!isOpen) {
			setHeaderHeight(0);
		}
	}, [isOpen]);

	return (
		<AntdModal
			title={
				<Header
					title={title}
					headerRightContent={headerRightContent}
					onCancel={onCancel}
					setHeaderHeight={setHeaderHeight}
					centerContent={centerContent}
					headerClassName={headerClassName}
					isModalClosable={closable}
				/>
			}
			open={isOpen}
			centered
			footer={null}
			closable={false}
			className={`${styles["wrapper"]} ${className || ""}`}
			styles={{
				body: { ...bodyStyle, maxHeight: `calc(80vh - ${headerHeight}px)` },
			}}
			{...rest}
		>
			{children}
		</AntdModal>
	);
};

const Header = (props: HeaderComponentProps) => {
	const {
		title,
		headerRightContent,
		onCancel,
		setHeaderHeight,
		centerContent,
		headerClassName,
		isModalClosable,
	} = props;

	const headerRef = createRef<HTMLDivElement>();

	useEffect(() => {
		setHeaderHeight(headerRef.current?.offsetHeight || 0);
	});

	return (
		<div className={`${styles["header"]} ${headerClassName ?? ""}`} ref={headerRef}>
			<h1 className={styles["title"]}>{title}</h1>
			{centerContent && centerContent}
			<div className={styles["header-right-content"]}>
				{headerRightContent && headerRightContent}
				{isModalClosable && (
					<CustomButton
						className={styles["close-button"]}
						onClick={onCancel}
						icon={<img src={closeIcon} alt="close" />}
						type="text"
					/>
				)}
			</div>
		</div>
	);
};

export default Modal;
