import { useRef } from "react";
import { Select as AntdSelect, SelectProps } from "antd";

import DropdownArrowIcon from "../../../assets/images/arrow-dropdown.svg";

import styles from "./select.module.scss";

interface IProps extends SelectProps {
	wrapperClassName?: string;
	selectClassName?: string;
}

export const Select = ({
	showSearch,
	loading,
	wrapperClassName,
	selectClassName,
	...rest
}: IProps) => {
	const ref = useRef<any>();

	return (
		<div className={`${styles["wrapper"]} ${wrapperClassName ?? ""}`} ref={ref}>
			<AntdSelect
				showSearch={showSearch ?? true}
				className={`${styles["dropdown-wrapper"]} ${selectClassName ?? ""}`}
				getPopupContainer={() => ref.current}
				suffixIcon={
					loading ? undefined : (
						<img className={styles["dropdown-icon"]} src={DropdownArrowIcon} alt="dropdown-arrow" />
					)
				}
				{...rest}
				{...(loading ? { value: undefined, loading: true, disabled: rest.disabled ?? true } : {})}
			/>
		</div>
	);
};
