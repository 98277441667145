import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { api } from "../../api";
import { useQuery } from "../../hooks";
import Spinner from "../../components/spinner";
import { getApiErrorMsg, getApiResponseMsg } from "../../utils/object-util";
import HeaderLayout from "../../components/layouts/header-layout";
import CandidateBasicForm from "../job-requisition/job-preview/candidate-shortlist-form/candidate-basic-form";
import CandidateProfessionalForm from "../job-requisition/job-preview/candidate-shortlist-form/candidate-professional-form";
import CandidateEducationForm from "../job-requisition/job-preview/candidate-shortlist-form/candidate-education-form";
import CandidateSkillsForm from "../job-requisition/job-preview/candidate-shortlist-form/candidate-skills-form";
import { formatAddressForInputField } from "../../utils/function-utils";
import { IAddress } from "../../utils/common-interfaces";
import { addressTypes } from "../../constants";
import CustomButton from "../../components/button";

import styles from "./candidatePublicForm.module.scss";

const CandidatePublicForm = () => {
	const [form] = Form.useForm();
	const { query } = useQuery();
	const token = query.get("token");
	const navigate = useNavigate();

	const [loadingDetails, setLoadingDetails] = useState(true);
	const [candidateData, setCandidateData] = useState<any>();
	const [submitting, setSubmitting] = useState(false);
	const [isSubmitFailed, setIsSubmitFailed] = useState(false);
	const [calendarAppUrl, setCalendarAppUrl] = useState<string>("");

	const goToLogin = () => {
		navigate("/", { replace: true });
	};

	const getCandidateDetails = () => {
		if (token) {
			setLoadingDetails(true);
			api
				.get({
					path: `/public/candidates`,
					params: { token },
					service: "job",
				})
				.then((resp) => {
					if (resp?.data?.data) {
						const obj = resp.data?.data || {};
						if (obj.education?.length) {
							obj.education.forEach((el: any, elIndex: number) => {
								obj.education[elIndex].joining_year = dayjs(el.joining_year, "DD-MM-YYYY");
								obj.education[elIndex].completion_year = dayjs(el.completion_year, "DD-MM-YYYY");
							});
						}
						if (obj.l_working_day) {
							obj.l_working_day = dayjs(obj.l_working_day, "DD-MM-YYYY");
						}
						if (obj.expected_joining_date) {
							obj.expected_joining_date = dayjs(obj.expected_joining_date, "DD-MM-YYYY");
						}
						if (obj.address) {
							const formattedAddress = formatAddressForInputField(obj.address);
							obj.address = formattedAddress;
						}
						if (obj.skills_evaluate) {
							const skillsArr = obj.skills_evaluate.map((el: any) => el.skills);
							obj.skills = skillsArr; // skills field is not coming from backend
						}
						setCandidateData(obj);
						form.setFieldsValue(obj);
					}
				})
				.catch((err) => {
					message.error({
						content: getApiErrorMsg(err),
						key: "candidate-public-form",
						duration: 3,
					});
					goToLogin();
				})
				.finally(() => setLoadingDetails(false));
		} else {
			goToLogin();
		}
	};

	// const getToken = () => {
	//   api
	//     .get({
	//       path: `/candidate_token_verification/?verification_token=${token}&candidate=${id}`,
	//       service: "job",
	//     })
	//     .then((resp) => {
	//       if (resp.data?.token_status === "200") {
	//         getCandidateDetails();
	//       } else {
	//         navigate("/calendly-error", { replace: true });
	//       }
	//     })
	//     .catch(() => navigate("/calendly-error", { replace: true }));
	// };

	useEffect(() => {
		if (token) {
			getCandidateDetails();
		}
	}, [token]);

	useEffect(() => {
		const eventListener = (event: any) => {
			if (event?.data && event?.data?.source === "Calendar App") {
				navigate(`/calendly-success?token=${token}`, {
					replace: true,
					state: event.data,
				});
			}
		};

		window.addEventListener("message", eventListener);
		return () => {
			window.removeEventListener("message", eventListener);
			document.body.classList.remove("remove-body-background");
		};
	}, []);

	const onFinish = (fieldsValue: any) => {
		if (token) {
			const values = {
				...candidateData,
				...fieldsValue,
				confirmed: true,
				l_working_day: fieldsValue["l_working_day"]
					? dayjs(fieldsValue["l_working_day"]).format("DD-MM-YYYY")
					: undefined,
				expected_joining_date: fieldsValue["expected_joining_date"]
					? dayjs(fieldsValue["expected_joining_date"]).format("DD-MM-YYYY")
					: undefined,
				education: fieldsValue.education?.map((el: any) => ({
					university_id: el.university_id,
					degree_id: el.degree_id,
					specialisation_id: el.specialisation_id,
					cgpa: el.cgpa,
					joining_year: dayjs(el.joining_year).format("DD-MM-YYYY"),
					completion_year: dayjs(el.completion_year).format("DD-MM-YYYY"),
				})),
			};

			const formattedAddress: IAddress[] = [];

			addressTypes.forEach((el: string) => {
				const valuesAddress = values.address[el];

				if (valuesAddress) {
					formattedAddress.push({
						name: el,
						address_line: valuesAddress.address_line,
						country: valuesAddress.country,
						city: valuesAddress.city,
						state: valuesAddress.state,
					});
				}
			});

			values.address = formattedAddress;
      
			if (values.current_ctc) values.current_ctc = parseFloat(values.current_ctc);

			delete values.skills;
			delete values.stages;
			setSubmitting(true);
			api
				.put({
					path: `/public/candidates`,
					params: { token },
					service: "job",
					formdata: values,
				})
				.then((response) => {
					message.success({
						content: getApiResponseMsg(response),
						key: "candidate-pubmic-form",
						duration: 3,
					});
					const { full_name, email } = response.data.updatedData;
					const url =
						// candidateData.stages[0].stage.interviewer.team_members[0].calendar_event_link.replace(
						//   "api-calendar",
						//   "calendar"
						// );
						candidateData?.stages[0]?.stage?.interviewer?.team_members[0]?.calendar_event_link?.replace(
							"8001",
							"3001"
						);
					setCalendarAppUrl(`${url}?name=${full_name}&email=${encodeURIComponent(email)}`);
					document.body.classList.add("remove-body-background");
					// const interviewerId = requisitionData?.pipeline?.stages.find(
					//   (el: StageCardProps) => isNotEmptyArray(el.interviewerId)
					// )?.interviewerId[0];
					// if (interviewerId) {
					//   navigate(
					//     `/calendly-invite?interviewer_id=${interviewerId}&candidate_id=${id}&verification_token=${token}`,
					//     {
					//       replace: true,
					//     }
					//   );
					// } else {
					//   goToLogin();
					// }
				})
				.catch((err) => {
					message.error({
						content: getApiErrorMsg(err),
						key: "candidate-public-form",
						duration: 10,
					});
				})
				.finally(() => setSubmitting(false));
		}
	};

	const onFinishFailed = () => {
		setIsSubmitFailed(true);
	};

	return calendarAppUrl ? (
		<iframe src={calendarAppUrl} title="Calendar App" style={{ height: "100vh", width: "100vw" }} />
	) : (
		<HeaderLayout>
			<div className={styles["container"]}>
				<div className={styles["wrapper"]}>
					{loadingDetails && <Spinner />}
					{!loadingDetails && candidateData && (
						<>
							<div className={styles["intro-para"]}>
								<p className={styles["head-text"]}>Dear {candidateData?.first_name},</p>
								<p className={styles["head-text"]}>
									Please verify your key profile details and schedule your next round inverview:
								</p>
							</div>

							<Form
								form={form}
								id="shortlist-candidate-confirmation-form"
								name="shortlist-candidate-confirmation-form"
								initialValues={candidateData}
								requiredMark={false}
								labelCol={{ span: 24 }}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								validateTrigger={["onBlur", "onChange"]}
								className={styles["shortlist-candidate-confirmation-form"]}
								disabled={submitting}
							>
								<div className={styles["basic-info"]}>
									<h3 className={styles["section-title"]}>Basic Information</h3>
								</div>

								<CandidateBasicForm fromPublicPage />

								<div className={styles["basic-info"]}>
									<h3 className={styles["section-title"]}>Professional Details</h3>
								</div>

								<CandidateProfessionalForm fromPublicPage />

								<div className={styles["basic-info"]}>
									<h3 className={styles["section-title"]}>Education Details</h3>
								</div>

								<CandidateEducationForm fromPublicPage />

								<div className={styles["basic-info"]}>
									<h3 className={styles["section-title"]}>Skill Evaluation</h3>
								</div>

								<CandidateSkillsForm isSubmitFailed={isSubmitFailed} />

								<Form.Item className={styles["schedule-button"]}>
									<CustomButton loading={submitting} type="primary" htmlType="submit">
										Schedule your interview
									</CustomButton>
								</Form.Item>
							</Form>
						</>
					)}
				</div>
			</div>
		</HeaderLayout>
	);
};

export default CandidatePublicForm;
