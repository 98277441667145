import { useState } from "react";
import { Form, message } from "antd";

import PasswordPolicies from "../../components/password-policies";
import CustomButton from "../../components/button";
import { Input } from "../../components/form-fields";
import { api } from "../../api";
import { getApiErrorMsg } from "../../utils/object-util";
import { useCurrentUser } from "../../hooks";

import SectionInfo from "./section-info";
import { ITenant } from ".";
import styles from "./tenant-sign-up.module.scss";

interface IProps {
	onSubmitCallback: () => void;
	tenant?: ITenant;
	token: string | null;
}

const FirstStepForm = (props: IProps) => {
	const { onSubmitCallback, tenant, token } = props;

	const [form] = Form.useForm();
	const [saving, setSaving] = useState(false);
	const { syncUser, loadCurrentUser } = useCurrentUser();

	const onSubmit = ({ password }: { password: string }) => {
		setSaving(true);
		api
			.put({
				path: `/users/admin-signup?token=${token}`,
				formdata: {
					password: password,
				},
				service: "auth",
			})
			.then((res) => {
				localStorage.setItem("user-access-token", res.data.data.token);
				loadCurrentUser(syncUser, "You've successfully signed up.");
				onSubmitCallback();
			})
			.catch((error) =>
				message.error({
					content: getApiErrorMsg(error),
				})
			)
			.finally(() => setSaving(false));
	};

	return (
		<>
			<SectionInfo
				heading="Sign up as an organisation"
				// subHeading="Sign up with your work google account or use the form"
			/>
			{/* <CustomButton className={styles["google-button"]} type="default">
        <Google /> <span>Sign in with Google</span>
      </CustomButton>
      <div className={styles["divider-or"]}>
        <div className={styles["divider"]} />
        <span>or</span>
        <div className={styles["divider"]} />
      </div> */}
			<Form
				name="sign-up-form"
				form={form}
				fields={[{ name: ["email"], value: tenant?.admin_email }]}
				initialValues={{ email: tenant?.admin_email }}
				requiredMark={false}
				onFinish={onSubmit}
				disabled={saving}
				layout="vertical"
				validateTrigger={["onBlur", "onChange"]}
				className={styles["form"]}
			>
				<Form.Item
					label="Email address"
					name="email"
					rules={[
						{ required: true, message: "Please input your email!" },
						{ type: "email", message: "Please input valid email!" },
					]}
				>
					<Input placeholder="Enter email address" disabled={!!tenant?.admin_email} />
				</Form.Item>
				<PasswordPolicies
					rulesCustomClass={styles["password-validation"]}
					itemProps={{
						label: "Password",
						name: "password",
					}}
					inputProps={{
						placeholder: "Create a password",
					}}
					wrapperClass={styles["password-input-wrapper"]}
				/>
				<Form.Item>
					<CustomButton
						type="primary"
						className={styles["submit-btn"]}
						htmlType="submit"
						loading={saving}
						disabled={saving}
					>
						Create Account
					</CustomButton>
				</Form.Item>
			</Form>
		</>
	);
};

export default FirstStepForm;
