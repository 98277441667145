import { Radio as AntdRadio, RadioGroupProps, RadioProps } from "antd";

import styles from "./radio.module.scss";

interface CustomRadioProps extends  Omit<RadioProps, "title"> { //Check if required
  title?: string | React.ReactNode;
}

export const Radio = (props: CustomRadioProps) => {
  const { title, children, className, ...extraProps } = props;

  return (
    <AntdRadio
      className={`${styles["radio"]} ${className || ""}`}
      {...extraProps}
    >
      {title}
      {children}
    </AntdRadio>
  );
};

const Group = ({ className, ...rest }: RadioGroupProps) => {
  return (
    <AntdRadio.Group
      className={`${styles["radio"]} ${className || ""}`}
      {...rest}
    />
  );
};

export const RadioTwo = (props: RadioProps) => {
  const { children, className, ...extraProps } = props;

  return (
    <AntdRadio
      className={`${styles["radio-two"]} ${className || ""}`}
      {...extraProps}
    >
      {children}
    </AntdRadio>
  );
};

const GroupTwo = ({ className, ...rest }: RadioGroupProps) => {
  return (
    <AntdRadio.Group
      className={`${styles["radio-two"]} ${className || ""}`}
      {...rest}
    />
  );
};

Radio.Group = Group;
RadioTwo.Group = GroupTwo;
