import { isNotEmptyArray, isString } from "./type-util";

/**
 * Getting the error response message
 *
 * @param   {Object}    error      The error response.
 * @returns {String}
 */
export function getApiErrorMsg(error: any) {
  if (error?.response?.data?.display_message?.message) {
    return error?.response?.data?.display_message?.message;
  } else if (error?.response?.data?.detail) {
    return error?.response?.data?.detail;
  } else if (isString(error?.response?.data?.message)) {
    return error.response.data.message;
  } else if (
    isNotEmptyArray(error?.response?.data?.error?.details) &&
    isString(error.response.data.error.details[0])
  ) {
    return error.response.data.error.details[0];
  } else if (
    isNotEmptyArray(error?.response?.data?.error?.details) &&
    isNotEmptyArray(error.response.data.error.details[0]) &&
    isString(error.response.data.error.details[0][0])
  ) {
    return error.response.data.error.details[0][0];
  } else if (isString(error?.response?.data?.error?.message)) {
    return error.response.data.error.message;
  } else if (isString(error?.response?.data?.detail?.message)) {
    return error.response.data.detail.message;
  } else if (isString(error?.message)) {
    return error.message;
  } else {
    return "Something went wrong. Please try again";
  }
}

/**
 * Getting the success response message
 *
 * @param   {Object}    response      The success response.
 * @returns {String}
 */
export function getApiResponseMsg(resp: any) {
  if (isString(resp?.data?.message)) {
    return resp.data.message;
  } else if (isString(resp?.message)) {
    return resp.message;
  } else if (isString(resp?.data?.display_message?.message)) {
    return resp?.data?.display_message?.message;
  } else {
    return "Successfully Executed!";
  }
}
