import { Link } from "react-router-dom";

import Notfound from "../../assets/images/404.svg";
import CustomButton from "../button";

import styles from "./notfound.module.scss";

interface IProps {
  text?: string;
  hideImage?: boolean;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
}

const NotFound = (props: IProps) => {
  const {
    text,
    hideImage = false,
    wrapperClassName,
    wrapperStyle,
    contentClassName,
    contentStyle,
  } = props;
  return (
    <div
      className={`${styles["wrapper"]} ${wrapperClassName ?? ""}`}
      style={wrapperStyle || {}}
    >
      <div
        className={`${styles["content"]} ${contentClassName ?? ""}`}
        style={contentStyle || {}}
      >
        {!hideImage && (
          <img className={styles["img"]} src={Notfound} alt="notfound" />
        )}
        <h1 className={styles["title"]}>{text ?? "Page not found"}</h1>
        <Link to="/">
          <CustomButton size="large" type="primary">Back To Home</CustomButton>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
