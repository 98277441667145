import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

const useRealTime = (type: "days" | "hours" | "minutes" | "seconds" | "milliseconds") => {
	const intervalMap: Record<typeof type, number> = useMemo(
		() => ({
			days: 43200000,
			hours: 1800000,
			minutes: 30000,
			seconds: 1000,
			milliseconds: 1,
		}),
		[]
	);
	const [currentTime, setCurrentTime] = useState(dayjs());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(dayjs());
		}, intervalMap[type]);

		return () => clearInterval(intervalId);
	}, []);

	return { currentTime };
};

export { useRealTime };
