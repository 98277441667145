import { useLocation } from "react-router-dom";

import { routeObjectType } from "../constants/routes";

const useServiceFromRoutes = (route: routeObjectType) => {
  const { pathname } = useLocation();
  const path = pathname.split("/");
  path.shift(); // Remove the first empty string from the array

  // The getServiceFromRoutes function is used to recursively find the matching routes based on the URL path segments.
  // If a subRoute is found, it returns the service of the subRoute; otherwise, it returns undefined.
  // It is called recursively until the pathLength is 0.
  // If the pathLength is 0, it returns the service of the currentRoute.

  const getServiceFromRoutes = (
    currentRoute = route,
    pathLength = pathname.split("/").length - 1,
    pathArray = path
  ): string | undefined => {
    if (pathArray[pathArray.length - 1] === "") {
      // Remove the last empty string from the pathArray if it exists
      pathArray.pop();
    }

    if (pathLength === 0) {
      const subRoute = Object.values(currentRoute?.subRoutes ?? {}).find(
        (r) => {
          if (r.route === pathname) {
            return r.service;
          }
        }
      );
      return subRoute ? subRoute?.service : undefined;
    } else {
      const getSubRoute = () => {
        return Object.values(currentRoute?.subRoutes ?? {}).find((r) => {
          if (
            Object.values(r.subRoutes ?? {}).find((r) => {
              return r?.route === pathname;
            })
          ) {
            return r ?? undefined;
          }
        });
      };

      return getServiceFromRoutes(getSubRoute(), pathLength - 1); // Recursively call the getServiceFromRoutes function with the found subRoute and a decremented pathLength
    }
  };

  return getServiceFromRoutes; // Return the getServiceFromRoutes function to be used in the component
};

export { useServiceFromRoutes }; // Export the useServiceFromRoute custom hook
