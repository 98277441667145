import React,{useState} from 'react';
import  Drawer  from '../../../components/drawer/new';
import styles from "./custom-drawer.module.scss";

interface CustomDrawerProps {
  visible: boolean;
  title: string;
  onClose: () => void;
  content: React.ReactNode;
  className?:string;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({ visible, title, onClose, content,className }) => {
  const [drawerState, setDrawerState] = useState<{
		title: string;
		visible: boolean;
		content: React.ReactNode;
	}>({
		title: "",
		visible: false,
		content: null,
	});
  
  // const showDrawer = (content: React.ReactNode, title: string) => {
	// 	setDrawerState({ visible: true, content, title });
	// };

	// const closeDrawer = () => {
	// 	setDrawerState({ visible: false, content: null, title: "" });
	// };
  
  
  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={600}
      className={`${styles["custom-drawer"]} ${className}`}
    >
      {content}
    </Drawer>
  );
};

export default CustomDrawer;
