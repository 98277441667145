import { useState, useEffect } from "react";
import { message } from "antd";

import HeaderLayout from "../../components/layouts/header-layout";
import { useLocation } from "react-router-dom";
import Spinner from "../../components/spinner";
import { api } from "../../api";
import { useQuery } from "../../hooks";
import { getApiErrorMsg } from "../../utils/object-util";

import styles from "./calendlyPublicSuccess.module.scss";

const CalendlyPublicSuccess = () => {
  const location = useLocation();
  const { query } = useQuery();
  const token = query.get("token");

  const [isUpdatingCandidate, setIsUpdatingCandidate] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      const { meeting, slotTime, isConfirmed } = location.state;
      const data = {
        stage: {
          is_interview_scheduled: isConfirmed,
          interview_time: slotTime,
          google_interview_link: meeting.googleMeetUrl, // Check
        },
      };
      api
        .put({
          path: `/public/candidates`,
          params: { token },
          service: "job",
          formdata: data,
        })
        .then(() => setIsUpdatingCandidate(false))
        .catch((error) => {
          message.error({
            content: getApiErrorMsg(error),
            duration: 3,
            key: "error",
          });
        });
    }
  }, []);

  return (
    <HeaderLayout>
      {isUpdatingCandidate ? (
        <Spinner />
      ) : (
        <div className={styles["container"]}>
          <div className={styles["confirm-text"]}>
            <p className={styles["text-first"]}>
              Thank you for confirming your interview
            </p>
            <p>You’ll receive an email with interview details.</p>
          </div>
        </div>
      )}
    </HeaderLayout>
  );
};

export default CalendlyPublicSuccess;
