import { ReactNode, useEffect, useState } from "react";
import DefaultEmptyImage from "../../assets/images/default-empty-img.svg";
import CustomButton from "../button";

import styles from "./empty.module.scss";

interface IPropsBase {
	hideImage?: boolean;
	image?: string;
	description?: string;
	button?: boolean;
	firstBtnText?: string;
	secondBtnText?: string;
	onFirstBtnClick?: () => void;
	onSecondBtnClick?: () => void;
	fullHeight?: boolean;
	wrapperClassName?: string;
	wrapperStyle?: React.CSSProperties;
	enableRandomEmptyImage?: boolean;
	randomEmptyImages?: {
		image: ReactNode;
		title: string | ReactNode;
		description?: string | ReactNode;
	}[];
	showFirstButton?: boolean;
}

type IPropsWithTitle = IPropsBase & {
	title?: string;
	hideTitle?: false;
};

type IPropsWithoutTitle = IPropsBase & {
	hideTitle: true;
};

export type IProps = IPropsWithTitle | IPropsWithoutTitle;

const Empty = (props: IProps) => {
	const {
		image,
		hideImage = false,
		description,
		button,
		firstBtnText,
		secondBtnText,
		fullHeight,
		wrapperClassName,
		wrapperStyle,
		hideTitle,
		enableRandomEmptyImage = false,
		randomEmptyImages,
		showFirstButton = true,
		onFirstBtnClick,
		onSecondBtnClick,
	} = props;

	const [randomIndex, setRandomIndex] = useState(0);

	useEffect(() => {
		if (enableRandomEmptyImage && randomEmptyImages?.length) {
			const randomIndex = Math.floor(Math.random() * randomEmptyImages.length);
			setRandomIndex(randomIndex);
		}
	}, []);

	return (
		<div
			className={`${styles["wrapper"]} ${
				fullHeight ? styles["heightfull"] : ""
			} ${wrapperClassName || ""}`}
			style={wrapperStyle}
		>
			<div className={`${styles["content"]}`}>
				{!hideImage &&
					(enableRandomEmptyImage && randomEmptyImages ? (
						randomEmptyImages[randomIndex].image
					) : (
						<img className={styles["img"]} src={image || DefaultEmptyImage} alt="No data" />
					))}
				{!hideTitle && (
					<h1 className={styles["title"]}>
						{enableRandomEmptyImage && randomEmptyImages
							? randomEmptyImages[randomIndex].title
							: props.title || "It's all empty here!"}
					</h1>
				)}
				{(description ||
					(enableRandomEmptyImage &&
						randomEmptyImages &&
						randomEmptyImages[randomIndex].description)) && (
					<p
						className={`${styles["description"]} ${hideTitle ? styles["only-description"] : ""} ${hideImage ? styles["no-img"] : ""}`}
					>
						{enableRandomEmptyImage && randomEmptyImages
							? randomEmptyImages[randomIndex].description
							: description}
					</p>
				)}
				{button && (
					<div className={styles["empty-btn-wrapper"]}>
						{showFirstButton && (
							<CustomButton type="default" onClick={onFirstBtnClick} id="empty-first-button">
								{firstBtnText || "Learn More"}
							</CustomButton>
						)}
						<CustomButton type="primary" onClick={onSecondBtnClick} id="empty-second-button">
							{secondBtnText || "Add Candidate"}
						</CustomButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default Empty;
