import styles from "./baseCard.module.scss";

interface IProps {
	footer: React.ReactNode;
	footerWrapperClassName: string;
	footerWrapperStyle: React.CSSProperties;
	topBorder?: boolean;
}

const BaseCardFooter = (props: IProps) => {
	const { footer, footerWrapperClassName, footerWrapperStyle, topBorder = true } = props;

	return (
		<div
			className={`${styles["base-card-footer"]} ${footerWrapperClassName ?? ""}`}
			style={{
				borderTop: topBorder ? "1px solid rgba(217, 217, 217, 1)" : undefined,
				...footerWrapperStyle,
			}}
		>
			{footer}
		</div>
	);
};

export default BaseCardFooter;
