import { useEffect, useState } from "react";

import { ReactComponent as LogOutIcon } from "../../assets/images/log-out.svg";
import CustomButton from "../../components/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authService } from "../../auth";
import { useCurrentUser } from "../../hooks";
import { api } from "../../api";
import { getApiErrorMsg } from "../../utils/object-util";
import Spinner from "../../components/spinner";
import ErrorPage from "../../pages/error-page";

import Step from "./step";
import FirstStepForm from "./first-step-form";
import SecondStepForm from "./second-step-form";
import ThirdStepForm from "./third-step-form";
import TenantOnboardingLayout from "./tenant-onboarding-layout";
import styles from "./tenant-sign-up.module.scss";

export interface ITenant {
	id: string;
	organization_name: string;
	status: string;
	admin_email: string;
	country: null;
	size: null;
	calendar_organisation_id: null;
	calendar_api_key: null;
	calendar_api_secret: null;
	is_verified: boolean;
	created_at: string;
	updated_at: string;
	department_id: null;
	tier_id: string;
	roles: Role[];
}

export interface Role {
	id: string;
	name: string;
	description: string;
	tenant_id: string;
	created_by: null;
	is_custom: boolean;
	is_default: boolean;
	is_removable: boolean;
	is_editable: boolean;
	is_super: boolean;
	created_at: string;
	updated_at: string;
	policies: Policy[];
}

export interface Policy {
	id: string;
	service: string;
	scope: string[];
	role_id: string;
	created_at: string;
	updated_at: string;
}

const TenantSignUp = () => {
	const { user } = useCurrentUser();
	const tenantSize = user?.tenant?.size ? 2 : 1;
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [activeStep, setActiveStep] = useState(authService.isAuthenticated() ? tenantSize : 0);
	const [tenant, setTenant] = useState<ITenant>();
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [authError, setAuthError] = useState("");

	const token = searchParams.get("token");
	const steps = ["Let’s sign up", "Organisation details", "Personal details"];

	useEffect(() => {
		if (token) {
			setIsAuthenticating(true);
			api
				.get({
					path: `/public/tenants?token=${token}`,
					service: "auth",
				})
				.then((res) => {
					if (res?.data?.status === "success") {
						if (res.data.data?.status === "Active") {
							navigate("/login");
						}
						setTenant(res.data.data);
					}
				})
				.catch((error) => {
					setAuthError(getApiErrorMsg(error));
				})
				.finally(() => setIsAuthenticating(false));
		} else {
			setIsAuthenticating(false);
		}
	}, [token]);

	return (
		<TenantOnboardingLayout
			headerRightContent={
				<div className={styles["header-right-content"]}>
					{authService.isAuthenticated() ? (
						<CustomButton
							type="default"
							onClick={() => {
								authService.logout();
							}}
							icon={<LogOutIcon />}
						>
							Log Out
						</CustomButton>
					) : (
						<>
							<div className={styles["text"]}>Already have an account?</div>
							<CustomButton type="default" onClick={() => navigate("/")}>
								Login
							</CustomButton>
						</>
					)}
				</div>
			}
		>
			{isAuthenticating ? (
				<Spinner />
			) : authError || (!token && activeStep === 0) ? (
				<ErrorPage mainPage error={authError} hideButton withoutHeader />
			) : (
				<>
					<div className={styles["steps"]}>
						{steps.map((step, index) => (
							<Step key={step} text={step} activeStep={activeStep} stepNumber={index} />
						))}
					</div>
					<div className={styles["form-layout"]}>
						{activeStep === 0 && (
							<FirstStepForm
								token={token}
								tenant={tenant}
								onSubmitCallback={() => setActiveStep((prev) => prev + 1)}
							/>
						)}
						{activeStep === 1 && (
							<SecondStepForm
								onSubmitCallback={() => {
									setActiveStep((prev) => prev + 1);
								}}
							/>
						)}
						{activeStep === 2 && (
							<ThirdStepForm
								onSubmitCallback={() => {
									window.location.reload();
								}}
							/>
						)}
					</div>
				</>
			)}
		</TenantOnboardingLayout>
	);
};

export default TenantSignUp;
