import { InputNumber as AntdInputNumber, InputNumberProps } from "antd";

import styles from "./inputNumber.module.scss";

interface CustomInputProps extends InputNumberProps {
  className?: string;
  wrapperStyle?: React.CSSProperties;
}

const InputNumber = ({
  className,
  wrapperStyle,
  ...rest
}: CustomInputProps) => {
  return (
    <div className={styles["wrapper"]} style={wrapperStyle}>
      <AntdInputNumber
        className={`${styles["input-number"]} ${className ?? ""}`}
        controls={rest.controls ?? false}
        {...rest}
      />
    </div>
  );
};

export { InputNumber };
