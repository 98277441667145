import { Tooltip } from "antd";
import { Link } from "react-router-dom";

import HomeIcon from "../../assets/images/home.svg";

import styles from "./leftMenu.module.scss";

interface LeftItemInterface {
  menuCollapsed: boolean;
  title: string | React.ReactNode;
  icon?: string | boolean;
  isTabActive: boolean;
  redirectRoute: string;
  itemKey: string;
  customClass?: string;
  tooltip?: boolean;
}

const LeftNavItem = ({
  menuCollapsed,
  title,
  icon,
  isTabActive,
  redirectRoute,
  itemKey,
  customClass,
  tooltip = true,
}: LeftItemInterface) => {
  return (
    <Tooltip title={tooltip && menuCollapsed ? title : null} placement="right">
      <Link to={redirectRoute} id={`nav-link-${itemKey}`}>
        <div
          className={`${styles["menu-item"]} ${
            isTabActive ? styles["active-menu-item"] : ""
          } ${customClass}`}
        >
          {typeof icon === "string" && (
            <img alt="icon" src={icon ?? HomeIcon} className={styles["menu-item-icon"]} />
          )}
          <div
            className={`${styles["title"]} ${
              menuCollapsed && styles["hide-title"]
            }`}
          >
            {title}
          </div>
        </div>
      </Link>
    </Tooltip>
  );
};

export default LeftNavItem;
