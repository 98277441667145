import HeaderLayout from "../../components/layouts/header-layout";
import PrivateLayout from "../../components/layouts/private-layout";

import ErrorContent from "./errorContent";

interface IProps {
	mainPage?: boolean;
	error?: string;
	hideButton?: boolean;
	withoutHeader?: boolean;
}

const ErrorPage = (props: IProps) => {
	const { mainPage, error, hideButton = false, withoutHeader = false } = props;

	const LayoutComponent = mainPage ? HeaderLayout : PrivateLayout;

	return withoutHeader ? (
		<ErrorContent error={error} hideButton={hideButton} />
	) : (
		<LayoutComponent>
			<ErrorContent error={error} hideButton={hideButton} />
		</LayoutComponent>
	);
};

export default ErrorPage;
