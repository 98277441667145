import { BaseCardHeading } from "./heading";

import styles from "./baseCard.module.scss";
import BaseCardFooter from "./footer";

interface IBaseCardProps {
	title?: string;
	children?: React.ReactNode;
	wrapperClassName?: string;
	wrapperStyle?: React.CSSProperties;
	titleClassName?: string;
	titleStyle?: React.CSSProperties;
	footer?: React.ReactNode;
	footerWrapperClassName?: string;
	footerWrapperStyle?: React.CSSProperties;
	onWrapperClick?: () => void;
	footertTopBorder?: boolean;
}

const BaseCard = ({
	title,
	children,
	wrapperClassName,
	wrapperStyle,
	titleClassName,
	titleStyle,
	footer,
	footerWrapperClassName,
	footerWrapperStyle,
	onWrapperClick,
	footertTopBorder = true,
}: IBaseCardProps) => {
	return (
		<div
			className={`${styles.basecard} ${wrapperClassName ?? ""}`}
			style={wrapperStyle || {}}
			onClick={onWrapperClick}
		>
			{title && (
				<BaseCardHeading
					heading={title}
					className={titleClassName ?? ""}
					style={titleStyle || {}}
				/>
			)}

			{children}

			{footer && (
				<BaseCardFooter
					topBorder={footertTopBorder}
					footerWrapperClassName={footerWrapperClassName ?? ""}
					footer={footer}
					footerWrapperStyle={footerWrapperStyle || {}}
				/>
			)}
		</div>
	);
};

BaseCard.Heading = BaseCardHeading;

export { BaseCard };
