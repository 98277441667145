import Logo from "../../../assets/images/logo-title-black.svg";

import styles from "./headerLayout.module.scss";

interface Props {
  children: React.ReactNode;
}

const HeaderLayout = ({ children }: Props) => {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["logo-wrapper"]}>
        <img className={styles["logo-img"]} src={Logo} alt="logo" />
      </div>
      {children}
    </div>
  );
};

export default HeaderLayout;
