import React, { useEffect, useState, memo } from "react";
import { message } from "antd";
import Badges from "../badges";
import { api } from "../../../api";
import { getApiErrorMsg } from "../../../utils/object-util";
import { isNotEmptyArray } from "../../../utils/type-util";
import { ReactComponent as StepIcon } from "../../../assets/images/stepIcon.svg";
import { ReactComponent as StepChampion } from "../../../assets/images/stepChampion.svg";
import { ReactComponent as SleepIcon } from "../../../assets/images/sleepIcon.svg";
import { ReactComponent as EarlyBirdIcon } from "../../../assets/images/early-bird-icon.svg";
import { ReactComponent as HydrationHero } from "../../../assets/images/hydration-hero.svg";
import { ReactComponent as WorkoutIcon } from "../../../assets/images/workout-worrior.svg";
import { ReactComponent as NutritionIcon } from "../../../assets/images/nutrition-ninja.svg";
import { ReactComponent as CardioIcon } from "../../../assets/images/cardio-champion.svg";
import { ReactComponent as GoalGetter } from "../../../assets/images/goal-getter.svg";
import styles from "./my-badges.module.scss";
import KnowMore from "../knowmore";


interface MyBadgesProps {
  badgesArray?: {
    id: string;
    type: string;
    tenant_id: string;
    badges: {
      id: string;
      teamMemberId: string;
      badge_id: string;
      tenant_id: string;
      assigned_at: string;
    }[];
    count: number;
  }[];
}

const MyBadges = memo(({ badgesArray }: MyBadgesProps) => {
 

  const badgeIcons: { [key: string]: JSX.Element } = {
    Step_Champion: <StepChampion />,
    Step_Streak: <StepIcon />,
    Nutrition_Ninja: <NutritionIcon />,
    Cardio_Champion: <CardioIcon />,
    Hydration_Hero: <HydrationHero />,
    Early_Bird: <EarlyBirdIcon />,
    Workout_Warrior: <WorkoutIcon />,
    Sleep_Superstar: <SleepIcon />,
    Goal_Getter: <GoalGetter />,
  };

  return (
    <div className={styles["badges"]}>
      {isNotEmptyArray(badgesArray) ? (
        <>
          {badgesArray?.map((badge) => (
            <Badges
              key={badge.id}
              name={badge.type.replace(/_/g, " ")}
              badge={badgeIcons[badge.type] ?? null}
              badgeClassName={badge.count > 0 ? styles["color-badge"] : ""}
              description={badge.count > 0 ? badge.count : null}
            />
          ))}
        </>
      ) : (
        <div>No badges available.</div>
      )}
    </div>
  );
});

export default MyBadges;
