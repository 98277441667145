import { useState } from "react";

import { api } from "../api";
import { IPositionLevel } from "../utils/common-interfaces";

const useAllPositionLevels = () => {
	const [data, setData] = useState<IPositionLevel[]>();
	const [isLoading, setIsLoading] = useState(true);

	const fetchData = (include_positions = false, page = "1", limit = "100000") => {
		setIsLoading(true);
		return api
			.post({
				path: `/positions-levels/all`,
				service: "job",
				formdata: {
					include_positions,
					page,
					limit,
				},
			})
			.then((response) => {
				setData(response?.data?.data);
			})
			.finally(() => setIsLoading(false));
	};

	return { data, isLoading, fetchData };
};

export { useAllPositionLevels };
