import { ReactNode } from "react";

import { avatarBgColor } from "../../constants";

import styles from "./avatarIcon.module.scss";

interface IProps {
	firstName: string;
	lastName?: string;
	color: string;
	profilePic?: string;
	profileComponent?: ReactNode;
	wrapperClassName?: string;
	wrapperStyle?: React.CSSProperties;
	contentClassName?: string;
	contentStyle?: React.CSSProperties;
	allowFullFirst?: boolean;
}

const AvatarIcon = (props: IProps) => {
	const {
		firstName,
		lastName,
		color,
		profilePic,
		profileComponent,
		wrapperClassName,
		wrapperStyle,
		contentClassName,
		contentStyle,
		allowFullFirst,
	} = props;

	const getInitials = () => {
		let initial = allowFullFirst ? firstName : firstName?.[0]?.toUpperCase();
		const firstNameArr = firstName?.split(" ");
		if (lastName?.[0]) {
			initial += lastName[0].toUpperCase();
		} else if (firstNameArr?.length > 1 && !allowFullFirst) {
			initial += firstNameArr[firstNameArr?.length - 1][0].toUpperCase();
		}
		return initial;
	};

	return (
		<div className={`${styles["wrapper"]} ${wrapperClassName || ""}`} style={wrapperStyle}>
			{profileComponent ? (
				profileComponent
			) : profilePic ? (
				<img
					alt={getInitials()}
					src={profilePic}
					style={contentStyle}
					className={`${styles["content-img"]} ${contentClassName || ""}`}
				/>
			) : (
				<p
					style={{
						...(contentStyle || {}),
						backgroundColor: color || avatarBgColor.default,
					}}
					className={`${styles["content-text"]} ${contentClassName || ""}`}
				>
					{getInitials()}
				</p>
			)}
		</div>
	);
};

export default AvatarIcon;
