import React from "react";
import { Progress } from "antd";
import { BaseCard } from "../../../components/base-card";
import { ReactComponent as StarIcon } from "../../../assets/images/starIcon.svg";

import styles from "./goals-card.module.scss";
import EllipsesTooltip from "../../../components/tooltip";

interface GoalCardProps {
	title: string;
	trackingMetric: string;
	progress: number;
	footerValue: number;
	onClick: () => void;
	IconComponent: React.ElementType;
	amount?: number;
	isStreakStarted?: boolean | null;
	isStreakCompleted?: boolean | null;
	streakDays?: number | null;
	data?: any;
	getRewards?: number;
}

const GoalCard: React.FC<GoalCardProps> = ({
	title,
	trackingMetric,
	progress,
	footerValue,
	onClick,
	IconComponent,
	amount,
	data,
	isStreakStarted,
	isStreakCompleted,
	streakDays,
	getRewards,
}) => {
	return (
		<BaseCard
			onWrapperClick={onClick}
			wrapperClassName={`${styles.goals} ${isStreakStarted ? styles["highlight-border"] : ""}`}
		>
			{isStreakStarted ? (
				<Progress
					percent={progress}
					size="small"
					strokeColor={"#F7CF51"}
					format={() => `${streakDays}/30 Days`}
				/>
			) : (
				<IconComponent />
			)}
			<div className={styles["goals-wrapper"]}>
				<h4>{title}</h4>
				<EllipsesTooltip text={`Track: ${trackingMetric}`} className={styles["sub-heading"]}>
					Track: {trackingMetric}
				</EllipsesTooltip>
				<p className={styles["footer"]}>
					<StarIcon />
					<span className={styles["footer-text"]}>{getRewards}</span>
				</p>
			</div>
		</BaseCard>
	);
};

export default GoalCard;
