import styles from "./errorPage.module.scss";

interface IProps {
	error?: string;
	hideButton?: boolean;
}

const ErrorContent = (props: IProps) => {
	const { error, hideButton } = props;

	return (
		<div className={styles["wrapper"]}>
			<div className={styles["content"]}>
				<h1 className={styles["title"]}>{error || "Something went wrong! Please try again."}</h1>
				{!hideButton && (
					<a className={styles["link"]} href={location.href}>
						Retry
					</a>
				)}
			</div>
		</div>
	);
};
export default ErrorContent;
