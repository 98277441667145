import { ReactComponent as Logo } from "../../../assets/images/logo-title-black.svg";

import styles from "./tenant-onboarding-layout.module.scss";

type Props = {
	children: React.ReactNode;
	headerRightContent?: string | React.ReactNode;
};

const TenantOnboardingLayout = ({ children, headerRightContent }: Props) => {
	return (
		<div className={styles["wrapper"]}>
			<div className={styles["header"]}>
				<Logo />
				{headerRightContent ?? <></>}
			</div>
			<div className={styles["content"]}>{children}</div>
		</div>
	);
};

export default TenantOnboardingLayout;
