import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, message } from "antd";
import dayjs from "dayjs";

import {
	DatePicker,
	Input,
	Radio,
	Select,
	TextArea,
	AddSkills,
} from "../../../../../components/form-fields";
import { employmentTypes, noticePeriodList } from "../../../../../constants";
import { api } from "../../../../../api";
import { debounceMethod } from "../../../../../utils/function-utils";
import { IIdNamePair } from "../../../../../utils/common-interfaces";
import { getApiErrorMsg } from "../../../../../utils/object-util";
import { useCountryList } from "../../../../../hooks";

interface Props {
	requisitionId?: string;
	fromPublicPage?: boolean;
}

const CandidateProfessionalForm = (props: Props) => {
	const { requisitionId, fromPublicPage } = props;

	const form = Form.useFormInstance();
	const [requisitionLoading, setRequisitionLoading] = useState(false);
	const [skillsOptions, setSkillsOptions] = useState<IIdNamePair[]>([]);
	const [currentCompany, setCurrentCompany] = useState<IIdNamePair[]>([]);
	const [countries, setCountries] = useState<Array<string>>([]);
	const [states, setStates] = useState<Array<string>>([]);
	const [cityData, setCityData] = useState<Array<string>>([]);
	const { getCity, getCountries, getStates } = useCountryList();

	const showLastDate = Form.useWatch("notice_period", form);
	const lastWorkingDay = Form.useWatch("l_working_day", form);

	useEffect(() => {
		if (!fromPublicPage) fetchRequisition();
	}, [requisitionId]);

	useEffect(() => {
		if (getCountries) {
			const getCountriesList: Array<string> = getCountries();
			setCountries(getCountriesList);
		}
		searchCurrentCompany("");
		getSkills("");
	}, []);

	useEffect(() => {
		if (fromPublicPage) {
			const country = form.getFieldValue(["address", "professional", "country"]);
			const state = form.getFieldValue(["address", "professional", "state"]);
			handleCountryChange(country, true);
			handleStateChange(state, true);
		}
	}, [fromPublicPage]);

	const handleCountryChange = (val: string, trigger?: boolean) => {
		if (getStates) {
			const getStatesList = getStates(val);
			setStates(getStatesList);
		}
		if (!trigger) {
			form.setFieldValue(["address", "professional", "state"], undefined);
			form.setFieldValue(["address", "professional", "city"], undefined);
		}
		setCityData([]);
	};

	const handleStateChange = (val: string, trigger?: boolean) => {
		const getCounty = form.getFieldValue(["address", "professional", "country"]);
		if (getCity) {
			const getCityList = getCity(getCounty, val);
			setCityData(getCityList);
		}
		if (!trigger) {
			form.setFieldValue(["address", "professional", "city"], undefined);
		}
	};

	const fetchRequisition = () => {
		if (requisitionId) {
			setRequisitionLoading(true);
			api
				.get({
					path: `/requisitions/${requisitionId}`,
					service: "job",
				})
				.then((response) => form.setFieldValue("expected_job_role", response.data?.data?.title))
				.catch((err) => {
					message.error({
						content: getApiErrorMsg(err),
						key: "candidate-shortlist-form",
					});
				})
				.finally(() => setRequisitionLoading(false));
		}
	};

	const searchCurrentCompFunc = (value: string) => {
		api
			.get({
				path: `${fromPublicPage ? "/public" : ""}/companies?name=${
					value?.trim()?.toLowerCase() || ""
				}`,
				service: "job",
			})
			.then((response) => {
				const arr =
					response.data?.data?.filter(
						(el: any, index: number) =>
							response.data.data.findIndex((innerEl: any) => el.name === innerEl.name) === index
					) || [];
				setCurrentCompany(arr);
			});
	};

	const searchCurrentCompany = useCallback(debounceMethod(searchCurrentCompFunc), []);

	const disablePreviousDate = (current: any) => {
		return lastWorkingDay
			? !dayjs(current).subtract(1, "day").isAfter(lastWorkingDay)
			: current < dayjs().endOf("day");
	};

	const getSkills = (value: string) => {
		api
			.get({
				path: `${fromPublicPage ? "/public" : ""}/skills?name=${
					value?.trim()?.toLowerCase() || ""
				}`,
				service: "job",
			})
			.then((response) => {
				const arr =
					response.data?.data?.filter(
						(el: any, index: number) =>
							response.data.data.findIndex((innerEl: any) => el.name === innerEl.name) === index
					) || [];
				setSkillsOptions(arr);
			});
	};

	const skillSearch = useCallback(debounceMethod(getSkills), []);

	return (
		<Row gutter={20}>
			<Col sm={24} md={12}>
				<AddSkills
					label="Previous Company"
					name="company"
					options={currentCompany}
					onSearch={(val) => searchCurrentCompany(val)}
					isRequired
					apiUrl={`${fromPublicPage ? "/public" : ""}/companies`}
					isMultiple={false}
					requiredMessage="Please input current company!"
				/>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Current Job Title"
					name="title"
					rules={[
						{
							required: true,
							message: "Please input current job title!",
							whitespace: true,
						},
					]}
				>
					<Input placeholder="Enter Current Job Title" />
				</Form.Item>
			</Col>
			<Col sm={24} md={12}>
				<Form.Item
					label="Address"
					name={["address", "professional", "address_line"]}
					rules={[
						{
							required: true,
							message: "Please input address!",
							whitespace: true,
						},
					]}
				>
					<TextArea autoSize={{ minRows: 6, maxRows: 6 }} />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Country"
					name={["address", "professional", "country"]}
					rules={[
						{
							required: true,
							message: "Please select country!",
							whitespace: true,
						},
					]}
				>
					<Select
						options={countries?.map((el) => ({
							value: el,
							label: el,
						}))}
						onChange={(val) => handleCountryChange(val)} // To modify the trigger settings, send only the desired value to the change method, as Antd automatically includes the option parameter in the second argument by default.
						placeholder="Select country"
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="State"
					name={["address", "professional", "state"]}
					rules={[
						{
							required: states.length !== 0,
							message: "Please select state!",
							whitespace: true,
						},
					]}
				>
					<Select
						options={states?.map((el) => ({
							value: el,
							label: el,
						}))}
						onChange={(val) => handleStateChange(val)} // To modify the trigger settings, send only the desired value to the change method, as Antd automatically includes the option parameter in the second argument by default.
						placeholder="Select state"
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="City"
					name={["address", "professional", "city"]}
					rules={[
						{
							required: cityData.length !== 0,
							message: "Please select city!",
							whitespace: true,
						},
					]}
				>
					<Select
						options={cityData?.map((el) => ({
							value: el,
							label: el,
						}))}
						placeholder="Select city"
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Current CTC  (in lacs)"
					name="current_ctc"
					rules={[
						{
							required: true,
							message: "Please select Current CTC!",
						},
						() => ({
							validator(_, value) {
								if (value && value < 0) {
									form.setFieldValue("current_ctc", value * -1);
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input
						type="number"
						placeholder="Enter Current CTC"
						step={0.01}
						onChange={(e) => {
							const value = parseFloat(e.target.value);
							if (!isNaN(value)) {
								const roundedValue = Math.round(value * 100) / 100;
								form.setFieldValue("current_ctc", roundedValue);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Expected CTC  (in lacs)"
					name="expected_ctc"
					rules={[
						{
							required: true,
							message: "Please select expected CTC!",
						},
						() => ({
							validator(_, value) {
								if (value && value < 0) {
									form.setFieldValue("expected_ctc", value * -1);
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input
						type="number"
						placeholder="Enter Expected CTC"
						onChange={(e) => {
							const value = parseFloat(e.target.value);
							if (!isNaN(value)) {
								const roundedValue = Math.round(value * 100) / 100;
								form.setFieldValue("expected_ctc", roundedValue);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Experience (in years)"
					name="experience"
					rules={[
						{
							required: true,
							message: "Please input experience!",
						},
						() => ({
							validator(_, value) {
								if (value && value.startsWith("-")) {
									form.setFieldValue("experience", value.replace("-", ""));
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input type="number" placeholder="Enter Experience" />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				{/* {fromPublicPage ? (
          <Form.Item
            name="skills"
            label="Skills"
            rules={[
              {
                required: true,
                message: "Please select your skills",
              },
            ]}
          >
            <Select
              mode="tags"
              value={form.getFieldValue("skills")}
              options={skillsOptions.map((el) => ({
                label: el.name,
                value: el.id,
              }))}
              optionFilterProp="label"
            />
          </Form.Item>
        ) : ( */}
				<AddSkills
					label="Skills"
					name="skills"
					options={skillsOptions}
					onSearch={skillSearch}
					canAdd={!fromPublicPage}
					isRequired
				/>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Notice period"
					name="notice_period"
					rules={[
						{
							required: true,
							message: "Please select Notice period duration!",
						},
					]}
				>
					<Select
						options={noticePeriodList}
						placeholder="Select notice duration"
						optionFilterProp="label"
						onChange={(value) => {
							const label = noticePeriodList.find((el) => el.value === value)?.label;
							if (label?.includes("Days")) {
								form.setFieldValue(
									"l_working_day",
									dayjs().add(Number(label.split(" ")[0]), "days")
								);
							} else {
								form.setFieldValue("l_working_day", undefined);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Notice period negotiable?"
					name="n_period_negotiable"
					rules={[
						{
							required: true,
							message: "Please select YES or NO!",
						},
					]}
				>
					<Radio.Group>
						<Radio value={true} title="Yes" />
						<Radio value={false} title="No" />
					</Radio.Group>
				</Form.Item>
			</Col>

			{showLastDate === "on_notice_period" && (
				<Col sm={24} md={12}>
					<Form.Item
						label="Expected last working day"
						name="l_working_day"
						rules={[
							{
								required: true,
								message: "Please select last working day!",
							},
						]}
						dependencies={["notice_period"]}
					>
						<DatePicker
							format={"DD-MM-YYYY"}
							onChange={() => form.setFieldValue("expected_joining_date", undefined)}
						/>
					</Form.Item>
				</Col>
			)}

			<Col sm={24} md={12}>
				<Form.Item
					label="Expected Date of Joining"
					name="expected_joining_date"
					rules={[
						{
							required: true,
							message: "Please select joining date!",
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (showLastDate !== "on_notice_period") return Promise.resolve();
								if (!value) return Promise.resolve();

								const lastWorkingDay = getFieldValue("l_working_day");
								if (!lastWorkingDay) return Promise.resolve();

								if (dayjs(value).isSame(lastWorkingDay) || dayjs(value).isAfter(lastWorkingDay)) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Joining date must be after the last working day!")
									);
								}
							},
						}),
					]}
				>
					<DatePicker format={"DD-MM-YYYY"} disabledDate={disablePreviousDate} />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Employment type"
					name="employment_type"
					rules={[
						{
							required: true,
							message: "Please input employment type!",
						},
					]}
				>
					<Select
						options={employmentTypes}
						placeholder="Select employment type"
						disabled={fromPublicPage}
						optionFilterProp="label"
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label="Expected job role" name="expected_job_role">
					<Select
						disabled
						loading={requisitionLoading}
						options={[]}
						placeholder="Select expected job role"
					/>
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item
					label="Reason for change"
					name="change_reason"
					rules={[
						{
							required: true,
							message: "Please input reason for change!",
						},
					]}
				>
					<Input placeholder="Enter Reason for Change" />
				</Form.Item>
			</Col>
		</Row>
	);
};

export default CandidateProfessionalForm;
