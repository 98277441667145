import { Fragment, ReactNode } from "react";
import { Avatar, Divider, Dropdown, DropdownProps, Tooltip } from "antd";

import style from "./styles.module.scss";

interface IProps {
	maxItems?: number;
	items: {
		firstName: string;
		lastName?: string;
		color?: string;
		img?: string;
	}[];
	showExtra?: boolean;
	forceRenderExtra?: boolean;
	dropdownPlacement?: DropdownProps["placement"];
	customDropdownTrigger?: ReactNode;
	wrapperClassName?: string;
}

const AvatarArray = (props: IProps) => {
	const {
		items,
		maxItems = 3,
		dropdownPlacement = "bottomRight",
		showExtra = true,
		forceRenderExtra = false,
		customDropdownTrigger,
		wrapperClassName,
	} = props;

	const colors = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

	const getRandomColor = () => {
		return colors[Math.floor(Math.random() * colors.length)];
	};

	return (
		<div className={wrapperClassName ?? ""}>
			{items.slice(0, maxItems).map((item) => (
				<Tooltip
					key={item.firstName}
					title={item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName}
					placement="top"
				>
					<Avatar
						src={item.img}
						style={{
							backgroundColor: item.color ?? getRandomColor(),
							marginRight: "-5px",
							boxShadow: "0 0 0 2px #fff",
						}}
					>
						{item.firstName[0].toUpperCase()}
						{item.lastName ? item.lastName?.[0].toUpperCase() : ""}
					</Avatar>
				</Tooltip>
			))}

			{(forceRenderExtra || (showExtra && items.length > maxItems)) && (
				<Dropdown
					placement={dropdownPlacement}
					dropdownRender={() => (
						<div className={style["dropdown-wrapper"]}>
							{items.slice(maxItems).map((item) => (
								<Fragment key={item.firstName + item.lastName}>
									<div className={style["item"]}>
										<Avatar
											src={item.img}
											style={{ backgroundColor: item.color ?? getRandomColor() }}
											alt={item.firstName}
											size={24}
										>
											{item.firstName[0].toUpperCase()}
											{item.lastName ? item.lastName[0].toUpperCase() : ""}
										</Avatar>
										<span className={style["title"]}>
											{item.firstName || ""} {item.lastName ?? ""}
										</span>
									</div>
									{items.indexOf(item) !== items.length - 1 && <Divider style={{ margin: 0 }} />}
								</Fragment>
							))}
						</div>
					)}
				>
					{customDropdownTrigger ? (
						customDropdownTrigger
					) : (
						<Avatar
							style={{
								backgroundColor: "#f2f3f4",
								marginRight: "-5px",
								boxShadow: "0 0 0 2px #fff",
								color: "#262626",
								fontWeight: "500",
							}}
						>
							+{items.length - maxItems}
						</Avatar>
					)}
				</Dropdown>
			)}
		</div>
	);
};

export default AvatarArray;
