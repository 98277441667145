import Section from "../section";
import { BaseCard } from "../../../components/base-card";
import HeaderTwo from "../../../components/header-two";
import styles from "./week-activity.module.scss";
import { ReactComponent as Check } from "../../../assets/images/checkIcon.svg";
import { Flex } from "antd";
import dayjs, { Dayjs } from "dayjs";

interface ActivityLog {
	id: string;
	name: string;
	calories: number;
	duration: number;
	dailyHealthTrackerId: string;
}

interface WeeklyActivity {
	date: string;
	activity_log?: ActivityLog[];
}

interface WeekActivityProps {
	weekly_activities?: WeeklyActivity[];
	currentDay: Dayjs;
}

const WeekActivity = ({ currentDay, weekly_activities }: WeekActivityProps) => {
	const startOfWeek = dayjs().startOf("week");
	const daysOfWeek = Array.from(Array(7).keys()).map((day) => dayjs(startOfWeek).add(day, "day"));

	// Define the type for the activitiesByDay object
	const activitiesByDay: { [key: string]: ActivityLog[] } = {};

	// Initialize the object with daysOfWeek as keys
	daysOfWeek.forEach((day) => {
		const dayString = day.format("YYYY-MM-DD");
		activitiesByDay[dayString] = [];
	});

	// Populate the object with the activities from weekly_activities
	weekly_activities?.forEach((entry) => {
		const entryDate = dayjs(entry.date).format("YYYY-MM-DD");
		if (activitiesByDay[entryDate] && entry.activity_log) {
			activitiesByDay[entryDate] = entry.activity_log;
		}
	});

	return (
		<Section leftSideContent={<h4> This week's activity</h4>}>
			<BaseCard
				footertTopBorder={false}
				wrapperClassName={styles["week"]}
				footerWrapperStyle={
					weekly_activities?.some((value) => value.activity_log && value.activity_log.length > 0)
						? {
								border: "1px solid red",
							}
						: undefined
				}
				footer={
					weekly_activities?.some((value) => value.activity_log && value.activity_log.length > 0)
						? weekly_activities.map(
								(activity) =>
									activity?.activity_log &&
									activity?.activity_log.length > 0 && (
										<>
											<Flex style={{ padding: "1rem 0 0.5rem 0" }} justify="space-between">
												<p className={styles["heading"]}>Strength Training</p>
												<p className={styles["sub-heading"]}>
													{dayjs(activity.date).format("dddd")}
												</p>
											</Flex>
											{activity.activity_log?.map((log) => (
												<Flex gap={16}>
													<p className={styles["strength-values"]}>{log.calories} CAL</p>
													<p className={styles["strength-values"]}>{log.duration / 60000} MINS</p>
												</Flex>
											))}
										</>
									)
							)
						: undefined
				}
			>
				<div className={styles["days-wrapper"]}>
					{daysOfWeek.map((day, index) => (
						<div key={index} className={`${styles["week-day"]} `}>
							<p
								className={`${styles["days"]} ${day.isSame(currentDay, "day") ? styles["highlighted"] : ""}  ${activitiesByDay[day.format("YYYY-MM-DD")].length > 0 ? styles["highlighted-day"] : ""}`}
							/>
							<span>{day.format("dd")}</span>
							{activitiesByDay[day.format("YYYY-MM-DD")].length > 0 && (
								<span className={styles["tick"]}>
									<Check />
								</span>
							)}
						</div>
					))}
				</div>
			</BaseCard>
		</Section>
	);
};
export default WeekActivity;
