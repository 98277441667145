import { Link } from "react-router-dom";

import AuthLayout from "../../components/layouts/auth-layout";
import SuccessIcon from "../../assets/images/success-icon.svg";
import CustomButton from "../../components/button";

import styles from "./forgotPasswordSuccess.module.scss";

const ForgotPasswordSuccess = () => {
  return (
    <AuthLayout>
      <div className={styles["container"]}>
        <img
          src={SuccessIcon}
          alt="success-icon"
          className={styles["success-icon"]}
        />

        <h2 className={styles["title"]}>Reset request sent!</h2>

        <p className={styles["desc"]}>
          Password reset request sent successfully. Please check your email to
          reset your password.
        </p>

        <Link to="/login" replace style={{ width: "100%" }}>
          <CustomButton type="primary" className={styles["okay-btn"]}>
            Okay
          </CustomButton>
        </Link>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordSuccess;
