
interface CurrencyInfo {
  countryName: string;
  code: string;
  symbol: string;
}

export const currencyMasterList: CurrencyInfo[] = [
  { countryName: "United States", code: "USD", symbol: "$" },
  { countryName: "India", code: "INR", symbol: "₹" },
  { countryName: "Eurozone", code: "EUR", symbol: "€" },
  { countryName: "United Kingdom", code: "GBP", symbol: "£" },
  { countryName: "Canada", code: "CAD", symbol: "CA$" },
  { countryName: "Australia", code: "AUD", symbol: "AU$" },
  { countryName: "Japan", code: "JPY", symbol: "¥" },
  { countryName: "Switzerland", code: "CHF", symbol: "CHF" },
  { countryName: "China", code: "CNY", symbol: "¥" },
  { countryName: "Sweden", code: "SEK", symbol: "kr" },
  { countryName: "New Zealand", code: "NZD", symbol: "NZ$" },
  { countryName: "Mexico", code: "MXN", symbol: "MX$" },
  { countryName: "Singapore", code: "SGD", symbol: "S$" },
  { countryName: "Hong Kong", code: "HKD", symbol: "HK$" },
  { countryName: "Norway", code: "NOK", symbol: "kr" },
  { countryName: "South Korea", code: "KRW", symbol: "₩" },
  { countryName: "Turkey", code: "TRY", symbol: "₺" },
  { countryName: "Russia", code: "RUB", symbol: "₽" },
  { countryName: "Brazil", code: "BRL", symbol: "R$" },
  { countryName: "South Africa", code: "ZAR", symbol: "R" },
  { countryName: "Malaysia", code: "MYR", symbol: "RM" },
  { countryName: "Saudi Arabia", code: "SAR", symbol: "ر.س" },
  { countryName: "Thailand", code: "THB", symbol: "฿" },
  { countryName: "Argentina", code: "ARS", symbol: "ARS" },
  { countryName: "Poland", code: "PLN", symbol: "zł" },
  { countryName: "Philippines", code: "PHP", symbol: "₱" },
  { countryName: "Indonesia", code: "IDR", symbol: "Rp" },
  { countryName: "Egypt", code: "EGP", symbol: "£" },
  { countryName: "Colombia", code: "COP", symbol: "COL$" },
  { countryName: "Nigeria", code: "NGN", symbol: "₦" },
];