import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, message } from "antd";

import AuthLayout from "../../components/layouts/auth-layout";
import { Input } from "../../components/form-fields";
import { useQuery } from "../../hooks";
import { api } from "../../api";
import { getApiErrorMsg } from "../../utils/object-util";
import PasswordPolicies from "../../components/password-policies";
import CustomButton from "../../components/button";

import styles from "./resetPassword.module.scss";

interface UserDataProps {
	email: string;
}

const ResetPassword = () => {
	const { query } = useQuery();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const token = query.get("token");

	const [loadingUser, setLoadingUser] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [userData, setUserData] = useState<UserDataProps>({
		email: "",
	});

	const fetchVerifyUsers = () => {
		if (token) {
			setLoadingUser(true);
			api
				.get({
					path: `/users/verify-token?token=${token}`,
					service: "auth",
				})
				.then((response) => {
					setUserData(response.data.data);
					form.setFieldsValue(response.data.data);
				})
				.catch((err) => {
					message.error({
						content: getApiErrorMsg(err),
						key: "user-data",
						duration: 3,
					});
					navigate("/login", { replace: true });
				})
				.finally(() => setLoadingUser(false));
		} else {
			navigate("/login", { replace: true });
		}
	};

	useEffect(() => {
		fetchVerifyUsers();
	}, [token]);

	const handleReset = (values: any) => {
		setSubmitting(true);
		api
			.put({
				path: `/users/reset-password?token=${token}`,
				service: "auth",
				formdata: values,
			})
			.then(() => {
				navigate("/reset-password-success", { replace: true });
			})
			.catch((err) => {
				message.error({
					content: getApiErrorMsg(err),
					key: "reset-password",
					duration: 3,
				});
			})
			.finally(() => setSubmitting(false));
	};

	return (
		<AuthLayout loading={loadingUser}>
			<div className={styles["container"]}>
				<h1 className={styles["header-title"]}>Reset Password</h1>
				<Form
					name="reset-form"
					form={form}
					initialValues={userData}
					disabled={submitting}
					onFinish={handleReset}
					autoComplete="off"
					requiredMark={false}
					labelCol={{ span: 24 }}
					validateTrigger={["onBlur", "onChange"]}
				>
					<Form.Item label="Email" name="email">
						<Input disabled placeholder="Enter Email Address" />
					</Form.Item>

					<PasswordPolicies
						itemProps={{ label: "Create new password", name: "password" }}
						isConfirmPassword
					/>

					<CustomButton
						loading={submitting}
						type="primary"
						htmlType="submit"
						className={styles["reset-submit-btn"]}
					>
						Reset password
					</CustomButton>
				</Form>
			</div>
		</AuthLayout>
	);
};

export default ResetPassword;
