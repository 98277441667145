import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { IAddress, ITask } from "./common-interfaces";
import { currencyMasterList } from "../constants/currency-master-list";
import { RefObject } from "react";
import { IUser } from "../constants/user-interfaces";

dayjs.extend(utc);
dayjs.extend(timezone);

export const debounceMethod = (func: any) => {
	let timer: ReturnType<typeof setTimeout>;
	return (...args: any) => {
		clearTimeout(timer);
		timer = setTimeout(() => func.apply(this, args), 500);
	};
};

export const formatAddressForInputField = (address: IAddress[]) => {
	const formattedAddress = { personal: {}, professional: {} };
	const personalAddress = address.find((el: IAddress) => el.name === "personal");
	if (personalAddress) {
		formattedAddress.personal = {
			...personalAddress,
		};
	}
	const workAddress = address.find((el: IAddress) => el.name === "professional");
	if (workAddress) {
		formattedAddress.professional = {
			...workAddress,
		};
	}
	return formattedAddress;
};

/**
 * Calculate tenure from a given date until the current date, and return it in a human-readable format.
 * @param date A string representing the date from which to calculate tenure. It should be in the format "DD-MM-YYYY".
 * @returns A string representing the tenure in years, months, and days, formatted as "X Years Y Months Z Days".
 * If the provided date is invalid or if the calculation fails, it returns "--".
 */
export const getTenureFromDate = (date: string, showDays?: boolean): string => {
	// Get the current date
	const today = dayjs();

	// Parse the provided date string using the format "DD-MM-YYYY"
	const formattedDate = dayjs(date);

	// Check if the parsed date is valid
	if (!formattedDate.isValid()) {
		return "--"; // Return "--" if the provided date is invalid
	}

	// Calculate the difference in days between the current date and the provided date
	const diffInDays = today.diff(formattedDate, "days");

	// Check if the difference in days is NaN (Not a Number)
	if (isNaN(diffInDays)) {
		return "--"; // Return "--" if the calculation fails
	}

	// Calculate years, months, and days based on the difference in days
	const years = Math.floor(diffInDays / 365);
	const remainingDays = diffInDays % 365;
	const months = Math.floor(remainingDays / 30);
	const days = remainingDays % 30;

	// Construct the tenure string based on the calculated years, months, and days
	let result = "";
	if (years > 0) {
		result += `${years} Year${years !== 1 ? "s" : ""}`;
	}
	if (months > 0) {
		if (result !== "") {
			result += " ";
		}
		result += `${months} Month${months !== 1 ? "s" : ""}`;
	}
	if (days > 0 && showDays) {
		if (result !== "") {
			result += " ";
		}
		result += `${days} Day${days !== 1 ? "s" : ""}`;
	}
	if (days > 0 && days < 30 && !years && !months && !showDays) {
		result += "< 1 month ";
	}

	// Return the formatted tenure string or "--" if it's empty
	return result !== "" ? result : "--";
};

export const getCurrencySymbol = (code: string): string => {
	const currency = currencyMasterList.find((item) => item.code === code);
	return currency ? currency.symbol : code;
};

export const flattenObject = (obj: any, parentKey = "") => {
	let flattened: Record<string, any> = {};

	for (const key in obj) {
		if (typeof obj[key] === "object" && obj[key] !== null) {
			const nestedObj = flattenObject(obj[key], `${parentKey}${key}_`);
			flattened = { ...flattened, ...nestedObj };
		} else {
			const keyArray = key.split("_");
			const formattedKey = keyArray
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" ");

			flattened[`${formattedKey}`] = obj[key];
		}
	}

	return flattened;
};

export const downloadFileBlob = (blob: Blob, fileName: string) => {
	const url = window.URL.createObjectURL(blob);
	// Create a link element
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
};

export const downloadFileFromUrl = (url: string, message: any) => {
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
			const fileName = url.split("/").pop();
			downloadFileBlob(blob, fileName ?? "file");
		})
		.catch((error) => {
			message.error({
				content: "Error downloading file",
				key: "download-file",
				duration: 3,
			});
		});
};

export const convertToCommaSeparatedString = (number: string | number) => {
	return Number(number).toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const deleteKeysFromObject = (obj: { [key: string]: any }, keysToDelete: string[]) => {
	keysToDelete.forEach((key) => {
		if (key in obj) {
			delete obj[key];
		}
	});
	return obj;
};

/**
 * Lightens the given color by a specified percentage.
 *
 * @param color - The color in hexadecimal format (e.g., "#RRGGBB").
 * @param percent - The percentage by which to lighten the color (0-100). Default is 75.
 * @returns The new color in hexadecimal format, representing the lightened color.
 *
 * @remarks
 * This function assumes that the input color is in hexadecimal format (e.g., "#RRGGBB").
 * The input color must be a valid hexadecimal color string without any alpha channel.
 * The percent parameter determines the degree of lightening, ranging from 0 to 100.
 * A percent value of 0 leaves the color unchanged, while a value of 100 produces white (#FFFFFF).
 *
 * Example Usage:
 * ```
 * const originalColor = "#336699";
 * const lightenedColor = lightenColor(originalColor, 20); // Lightens the color by 20%
 * ```
 */
export const lightenColor = (color: string, percent = 75) => {
	const parseColor = (colorVal: string) => parseInt(colorVal, 16);
	const stringifyColor = (colorVal: number) => Math.round(colorVal).toString(16).padStart(2, "0");

	// Parse the color into RGB components
	const rVal = parseColor(color.substring(1, 3));
	const gVal = parseColor(color.substring(3, 5));
	const bVal = parseColor(color.substring(5, 7));

	// Lighten each RGB component
	const newR = rVal + (255 - rVal) * (percent / 100);
	const newG = gVal + (255 - gVal) * (percent / 100);
	const newB = bVal + (255 - bVal) * (percent / 100);

	// Return the new color
	return `#${stringifyColor(newR)}${stringifyColor(newG)}${stringifyColor(newB)}`;
};

export function convertToMinutes(time: string) {
	const [hours, minutes] = time.split(":");
	return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
}

export function calculatePercentageInRange(
	startTime: string,
	endTime: string,
	currentTime: string
) {
	// Convert start time, end time, and current time to minutes
	const startTimeMinutes = convertToMinutes(startTime);
	const endTimeMinutes = convertToMinutes(endTime);
	const currentTimeMinutes = convertToMinutes(currentTime);

	// Calculate the total duration of the range
	const totalDuration = endTimeMinutes - startTimeMinutes;

	// Calculate the duration from start time to current time
	const currentDuration = currentTimeMinutes - startTimeMinutes;

	// Calculate the percentage
	const percentage = (currentDuration / totalDuration) * 100;

	// Return the percentage
	return percentage;
}

export const convertUtcToLocal = (utcTime: string, format: string = "HH:mm") =>
	dayjs
		.utc()
		.startOf("day")
		.add(Number(utcTime.split(":")[0]), "hours")
		.add(Number(utcTime.split(":")[1]), "minutes")
		.local()
		.format(format);

export const calculatePopoverPosition = (
	eventRef: RefObject<HTMLDivElement>,
	viewType?: string
) => {
	const element = eventRef.current;
	if (!element) return { top: 0, left: 0 };

	const rect = element.getBoundingClientRect();
	const popoverWidth = 420;
	const popoverHeight = 305;
	const space = {
		top: rect.top,
		right: window.innerWidth - rect.right,
		bottom: window.innerHeight - rect.bottom,
		left: rect.left,
	};

	let top, left;

	if (viewType === "day" || viewType === "today") {
		left = -(popoverWidth - rect.width) / 3;
		top = rect.height + 10;
	} else {
		if (space.right > popoverWidth) {
			left = rect.width + 10;
		} else if (space.left > popoverWidth) {
			left = -(popoverWidth + 10);
		} else {
			left = -(popoverWidth / 2 - rect.width / 2);
		}

		if (space.bottom > popoverHeight) {
			top = rect.height + 10;
		} else if (space.top > popoverHeight) {
			top = -(popoverHeight + 10);
		} else {
			top = rect.height + 10;
		}
	}

	return { top, left };
};

export const reorderArray = <T>(list: Array<T>, startIndex: number, endIndex: number): Array<T> => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export const duplicateItemAtIndex = (index: number, array: Array<any>): Array<any> => {
	let newArray = array?.slice();
	newArray.splice(index, 0, array[index]);
	return newArray;
};

export const findTaskByUniqueIdentifier = (tasks: ITask[], identifier: string): ITask | undefined =>
	tasks.find((task) => task.unique_identifier === identifier && !task.is_disabled);

export const capitalizeFirstLetter = (value: string) => {
	if (!value) return value;
	return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const checkScope = (user: IUser | undefined, policy: string, scope: string): boolean => {
	return !!user?.currentRole?.policies?.[policy]?.includes(scope);
};
