import { useEffect, useRef, useState } from "react";
import { Tooltip as AntdTooltip, TooltipProps } from "antd";

type Props = TooltipProps & {
  text: string | undefined;
  textClassName?: string;
  
};

const EllipsesTooltip = (props: Props) => {
  const { text, textClassName, ...rest } = props;

  const childRef = useRef<HTMLParagraphElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (
      childRef.current &&
      childRef.current.offsetWidth < childRef.current.scrollWidth
    ) {
      setIsEllipsisActive(true);
    } else {
      setIsEllipsisActive(false);
    }
  });

  return (
    <AntdTooltip
      title={isEllipsisActive ? text : undefined}
      {...rest}
      placement={rest.placement ?? "top"}
    >
      <p
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        ref={childRef}
        className={textClassName}
      >
        {text ?? "--"}
      </p>
    </AntdTooltip>
  );
};

export default EllipsesTooltip;
