import HeaderLayout from "../../components/layouts/header-layout";

import styles from "./calendlyPublicError.module.scss";

const CalendlyPublicError = () => {
  return (
    <HeaderLayout>
      <div className={styles["container"]}>
        <div className={styles["confirm-text"]}>
          <p className={styles["text-first"]}>
            Unable to process your request!
          </p>
          <p>Possible reason: The link has been expired!</p>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default CalendlyPublicError;
