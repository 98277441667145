import React from "react";
import { ReactComponent as WaterProgress } from "../../../assets/images/water-progress.svg";

interface WaterDropProgressProps {
  width?: number;
  height?: number;
  progress?: number;
}

const WaterDropProgress: React.FC<WaterDropProgressProps> = ({
  width = 70,
  height = 84,
  progress = 0,
}) => {
  const viewBoxHeight = 84;
  const waterHeight = progress * viewBoxHeight;
  const waveHeightOffset = 5;

  const wavePath = progress > 0
    ? `
      M0 ${viewBoxHeight - waterHeight - waveHeightOffset}
      Q 15 ${viewBoxHeight - waterHeight - waveHeightOffset - 10}, 30 ${viewBoxHeight - waterHeight - waveHeightOffset}
      T 60 ${viewBoxHeight - waterHeight - waveHeightOffset}
      T 90 ${viewBoxHeight - waterHeight - waveHeightOffset}
      V${viewBoxHeight}
      H0
      Z`
    : "";

  return (
    <svg width={width} height={height} viewBox="0 0 70 84">
      <defs>
        <clipPath id="waterDropClip">
          <path d="M32.8787 1.78175C34.0499 0.608409 35.9509 0.607569 37.1232 1.77987L59.5267 24.1833C64.3772 29.0307 67.6811 35.2076 69.0206 41.9329C70.36 48.6582 69.6748 55.6297 67.0517 61.9655C64.4285 68.3013 59.9852 73.7169 54.2838 77.5271C48.5824 81.3373 41.8791 83.3711 35.0217 83.3711C28.1643 83.3711 21.4609 81.3373 15.7595 77.5271C10.0581 73.7169 5.61484 68.3013 2.99167 61.9655C0.368495 55.6297 -0.316693 48.6582 1.02277 41.9329C2.36223 35.2076 5.66617 29.0307 10.5167 24.1833L32.8787 1.78175Z" />
        </clipPath>
      </defs>
      <WaterProgress />
     
      {progress > 0 && (
        <>
          <rect
            x="0"
            y={viewBoxHeight - waterHeight}
            width="70"
            height={waterHeight}
            fill="#3498db"
            clipPath="url(#waterDropClip)"
          />
          <path d={wavePath} fill="#3498db" clipPath="url(#waterDropClip)" />
        </>
      )}
    </svg>
  );
};

export default WaterDropProgress;
