import HeaderLayout from "../header-layout";
import Spinner from "../../spinner";
import LoginBg from "../../../assets/images/new-login-bg.svg";

import styles from "./authLayout.module.scss";

interface AuthLayoutProps {
	children?: React.ReactNode;
	leftContent?: {
		title: string | React.ReactNode;
		list: string[];
	};
	loading?: boolean;
}

const AuthLayout = ({ children, leftContent, loading }: AuthLayoutProps) => {
	return (
		<HeaderLayout>
			{loading && <Spinner />}
			{!loading && (
				<div
					className={`${styles["inner-wrapper"]} ${
						leftContent ? "" : styles["inner-wrapper-solo"]
					}`}
				>
					{leftContent && (
						<div className={styles["left-content"]}>
							<img src={LoginBg} alt="login-bg" style={{minHeight:"380px"}}/>
							<div className={styles["left-inner-content"]}>
								<p className={styles["left-content-title"]}>{leftContent.title}</p>
								<ul className={styles["left-content-list-wrapper"]}>
									{leftContent.list.map((list, index) => (
										<li className={styles["left-content-list-item"]} key={index}>
											{list}
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
					<div className={styles["right-content"]}>
						<div>{children}</div>
					</div>
				</div>
			)}
		</HeaderLayout>
	);
};

export default AuthLayout;
