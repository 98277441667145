import { useState } from "react";
import { ColumnsType } from "antd/es/table";

import AvatarArray from "../../../../../../components/AvatarArray";
import { isNotEmptyArray } from "../../../../../../utils/type-util";
import AvatarIcon from "../../../../../../components/avatar-icon";
import CustomButton from "../../../../../../components/button";
import Drawer from "../../../../../../components/drawer/new";
import Table from "../../../../../../components/table";
import { ReactComponent as BadgeIcon } from "../../../../../../assets/images/sqaud-badge.svg";
import { ReactComponent as GoldenBatchIcon } from "../../../../../../assets/images/gold-medal.svg";
import { ReactComponent as SilverBadgeIcon } from "../../../../../../assets/images/silver-medal.svg";
import { ReactComponent as BronzeBadgeIcon } from "../../../../../../assets/images/bronze-medal.svg";
import { ISquadData } from "..";

import styles from "./squadLeaderboardCard.module.scss";

interface LeaderBoardProps {
	squad: ISquadData;
	squadIndex: number;
}

const LeaderBoardCard = ({ squad, squadIndex }: LeaderBoardProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const columns: ColumnsType<any> = [
		{
			dataIndex: "number",
			key: "number",
			render: (text, record, index) => <p>{index + 1}</p>,
		},
		{
			key: "first_name",
			render: (value, record) => (
				<div className={`d-flex ${styles["team-member-squad"]}`}>
					<AvatarIcon
						firstName={record?.first_name}
						lastName={record?.last_name}
						color={record?.rgb_icon}
					/>
					{record?.full_name}
				</div>
			),
		},
		{
			dataIndex: "position",
			key: "position",
			width: 200,
			render: (__: any, record) => {
				return <div className="d-flex">{record?.position?.name}</div>;
			},
		},
	];

	const getBatchIcon = () => {
		switch (squadIndex) {
			case 0:
				return <GoldenBatchIcon width={45} height={45} />;
			case 1:
				return <SilverBadgeIcon width={45} height={45} />;
			case 2:
				return <BronzeBadgeIcon width={45} height={45} />;
			default:
				return "";
		}
	};

	return (
		<div className={styles["leaderboard-card-wrapper"]}>
			<div className={styles["squad-name"]}>
				<AvatarIcon
					wrapperClassName={styles["squad-batch"]}
					firstName={String(squadIndex + 1)}
					color="#F7CF51"
					profileComponent={getBatchIcon()}
				/>

				<p className={styles["squad-title"]}>{squad.name}</p>
			</div>
			<div className={styles["squad-members-points"]}>
				<p className={styles["points-title"]}>
					{squad.score || 0} <span style={{ fontWeight: "normal", fontSize: "14px" }}>points</span>
				</p>
				{isNotEmptyArray(squad.team_members) && (
					<div className="d-flex" style={{ gap: 10 }}>
						<AvatarArray
							maxItems={4}
							items={squad.team_members.map((item) => ({
								firstName: item.first_name,
								lastName: item.last_name,
								color: item.rgb_icon,
							}))}
							showExtra={false}
						/>
						<CustomButton type="link" onClick={() => setIsDrawerOpen(true)}>
							View Members
						</CustomButton>
					</div>
				)}
			</div>

			<Drawer
				width={700}
				open={isDrawerOpen}
				destroyOnClose
				onClose={() => setIsDrawerOpen(false)}
				headerClassName={styles.header}
				className={styles["drawer-leaderboard-wrapper"]}
				title={
					<div className={styles.banner}>
						<BadgeIcon />
						<div>
							<div className={styles.name}>{squad.name}</div>
							<div className={styles["sub-title"]}>
								{squad.team_members.length} Member{squad.team_members.length > 1 ? "s" : ""}
							</div>
						</div>
					</div>
				}
			>
				<Table
					rows={squad?.team_members || []}
					columns={columns}
					rowKey={(row) => row.id}
					pagination={false}
					bordered={false}
				/>
			</Drawer>
		</div>
	);
};

export default LeaderBoardCard;
