import HeaderTwo from "../../../components/header-two";
import { ReactElement } from "react";

import styles from "./section.module.scss";

interface IProps {
	rightSideContent?: ReactElement;
	leftSideContent?: ReactElement;
	children?: ReactElement;
	wrapperClassName?: string;
	childrenClassName?: string;
	childrenStyle?: React.CSSProperties;
}

const Section = ({
	rightSideContent,
	leftSideContent,
	children,
	wrapperClassName,
	childrenClassName,
	childrenStyle,
}: IProps) => {
	return (
		<>
			<HeaderTwo
				rightSideContent={rightSideContent}
				leftSideContent={leftSideContent}
				wrapperClassName={`${styles["header-wrapper"]} ${wrapperClassName ?? ""}`}
				leftSideContentWrapperClass={styles["title"]}
			/>
			<div className={`${styles["data-cards"]}  ${childrenClassName ?? ""}`} style={childrenStyle}>
				{children}
			</div>
		</>
	);
};
export default Section;
