import { ReactNode } from "react";
import { Popconfirm as AntdPopConfirm, PopconfirmProps } from "antd";

import styles from "./popConfirm.module.scss";

interface IProps extends PopconfirmProps {
	content: string | ReactNode;
	rootClassName?: string;
}

const Popconfirm = (props: IProps) => {
	const { content, rootClassName, ...rest } = props;
	return (
		<AntdPopConfirm
			{...rest}
			rootClassName={`${styles.root} ${rootClassName}`}
			okButtonProps={{ className: styles.okButton }}
			cancelButtonProps={{ className: styles.cancelButton }}
		>
			<div>{content}</div>
		</AntdPopConfirm>
	);
};

export default Popconfirm;
