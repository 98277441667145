import { PopconfirmProps, Tooltip } from "antd";
import { useRef } from "react";

import DeleteFileIcon from "../../assets/images/cross-icon-black.svg";
import ImageIcon from "../../assets/images/image.svg";
import PDFIcon from "../../assets/images/pdf.svg";
import WordIcon from "../../assets/images/word.svg";
import Popconfirm from "../pop-confirm";

import styles from "./uploadedFile.module.scss";

interface IBaseProps {
	url: string;
	canDownload?: boolean;
	size?: "small" | "large";
	wrapperStyle?: React.CSSProperties;
	icon?: boolean;
	noBorder?: boolean;
	name?: string;
}

interface PropsWithPopConfirm extends IBaseProps {
	onRemove: () => void;
	popover?: true;
	popConfirmProps?: PopconfirmProps;
}

interface PropsWithoutPopConfirm extends IBaseProps {
	onRemove?: () => void;
	popover?: never | false;
	popConfirmProps?: never | undefined;
}

type IProps = PropsWithPopConfirm | PropsWithoutPopConfirm;

const UploadedFile = (props: IProps) => {
	const {
		url,
		canDownload,
		onRemove,
		size,
		wrapperStyle,
		icon = true,
		noBorder = false,
		name,
		popover = false,
		popConfirmProps,
	} = props;
	const ref = useRef<HTMLDivElement>(null);

	const getFileNameFromUrl = (): string => {
		const tempName = url.substring(url?.lastIndexOf("/") + 1);
		return decodeURIComponent(tempName);
	};

	const getFileExtension = () => {
		const ext = name ? name.substring(name.lastIndexOf(".")) : url.substring(url.lastIndexOf("."));
		if (ext === ".png" || ext === ".jpeg" || ext === ".jpg") {
			return <img src={ImageIcon} alt="" key={url} />;
		} else if (ext === ".pdf") {
			return <img src={PDFIcon} alt="" key={url} />;
		} else if (ext === ".doc" || ext === ".docx") {
			return <img src={WordIcon} alt="" key={url} />;
		}
	};

	const fileName = name ?? getFileNameFromUrl();
	const getIcon = getFileExtension();

	const onDownload = () => {
		if (canDownload) {
			const aTag = document.createElement("a");
			aTag.href = url;
			aTag.download = fileName;
			aTag.target = "_blank";
			document.body.appendChild(aTag);
			aTag.click();
			document.body.removeChild(aTag);
		}
	};

	return (
		<div
			ref={ref}
			className={`${styles["file"]} ${noBorder ? styles["no-border"] : ""}`}
			style={{
				maxWidth: size === "small" ? "120px" : "200px",
				...wrapperStyle,
			}}
		>
			{icon && getIcon}
			<Tooltip title={fileName} placement="bottom">
				<p
					onClick={canDownload ? onDownload : () => null}
					style={{ cursor: canDownload ? "pointer" : "text" }}
					className={`${styles["file-name"]}`}
				>
					{fileName}
				</p>
			</Tooltip>
			{onRemove &&
				(popover ? (
					<Popconfirm
						title="Are you sure you want to delete?"
						content={
							<img
								src={DeleteFileIcon}
								className="cursor-pointer"
								style={{ margin: "auto 0", display: "block" }}
							/>
						}
						onConfirm={onRemove}
						placement="leftTop"
						{...popConfirmProps}
					/>
				) : (
					<img
						src={DeleteFileIcon}
						onClick={(e) => {
							e.stopPropagation();
							onRemove?.();
						}}
						className="cursor-pointer"
					/>
				))}
		</div>
	);
};

UploadedFile.defaultProps = {
	size: "small",
};

export default UploadedFile;
