import { useEffect, useState } from "react";

import { api } from "../../../api";
import { useQuery } from "../../../hooks";
import Spinner from "../../../components/spinner";
import { getApiErrorMsg } from "../../../utils/object-util";
import HeaderLayout from "../../../components/layouts/header-layout";
import ErrorPage from "../../../pages/error-page";

import styles from "./tenantEmailVerification.module.scss";

const TenantEmailVerification = () => {
	const { query } = useQuery();
	const token = query.get("token");

	const [isVerifyingTenant, setIsVerifyingTenant] = useState<boolean>(true);
	const [error, setError] = useState();

	useEffect(() => {
		if (token) {
			api
				.get({
					path: "/public/tenants/verify-email",
					params: { token },
					service: "auth",
				})
				.catch((error) => {
					setError(getApiErrorMsg(error));
				})
				.finally(() => setIsVerifyingTenant(false));
		}
	}, [token]);

	if (isVerifyingTenant) {
		return (
			<HeaderLayout>
				<Spinner />
			</HeaderLayout>
		);
	}

	if (error) {
		return <ErrorPage mainPage error={error} hideButton />;
	}

	return (
		<HeaderLayout>
			<div className={styles["container"]}>
				<div className={styles["confirm-text"]}>
					<p className={styles["text-first"]}>Thank you for confirming your email</p>
					<p>You’ll receive an email with the onboarding process details.</p>
				</div>
			</div>
		</HeaderLayout>
	);
};

export default TenantEmailVerification;
