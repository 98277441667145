import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";

import { useCurrentUser } from "../../hooks";

import { api } from "../../api";

import Spinner from "../../components/spinner";

const Fitbit = () => {
	const [searchParams] = useSearchParams();
	const { user, syncUser } = useCurrentUser();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const code = searchParams.get("code");
	
	useEffect(() => {
		api
			.post({ path: "/fitbit/login", formdata: { code: code }, service: "job" })
			.then((resp) => {
				if (resp.data) {
					navigate("/health?auth=1");

					if (syncUser && user) {
						const updatedUser = {
							...user,
							sessions_data: {
								...user.sessions_data,
								health: {
									fitbit_integrated: true,
								},
							},
						};
						syncUser(updatedUser);
					}
					message.success({
						content: resp.data.message,
						key: "success",
						duration: 3,
					});
				}
			})
			.catch((err) => {
				message.error({
					content: err?.response?.data?.message,
					key: "error",
					duration: 3,
				});
				navigate("/health");
			});
	}, []);

	return <>{isLoading && <Spinner />}</>;
};

export default Fitbit;
