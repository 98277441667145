import { useEffect, useState } from "react";
import { Form, message } from "antd";

import CustomButton from "../../components/button";
import { Input, Select } from "../../components/form-fields";
import { organisationSizes } from "../../constants";
import { api } from "../../api";
import { getApiErrorMsg, getApiResponseMsg } from "../../utils/object-util";
import { ICountry, IDepartment } from "../../utils/common-interfaces";
import { useCurrentUser, useCountryList } from "../../hooks";

import SectionInfo from "./section-info";
import styles from "./tenant-sign-up.module.scss";

interface IProps {
	onSubmitCallback: () => void;
}

const SecondStepForm = ({ onSubmitCallback }: IProps) => {
	const [form] = Form.useForm();
	const { user } = useCurrentUser();
	const { countries } = useCountryList();
	const [saving, setSaving] = useState(false);
	const [departments, setDepartments] = useState<IDepartment[]>();

	useEffect(() => {
		form.setFieldValue("org_name", user?.tenant.organization_name);
	}, [user]);

	useEffect(() => {
		api
			.get({
				path: `/departments`,
				service: "auth",
			})
			.then((res) => {
				setDepartments(res.data.data);
			})
			.catch((error) =>
				message.error({
					content: getApiErrorMsg(error),
				})
			);
	}, []);

	const onSubmit = (values: any) => {
		setSaving(true);
		api
			.put({
				path: `/tenants`,
				formdata: {
					country: values.country,
					size: values.size,
					department_id: values.department_id,
				},
				service: "auth",
			})
			.then((res) => {
				message.success({
					content: getApiResponseMsg(res),
				});
				if (onSubmitCallback) onSubmitCallback();
			})
			.catch((error) =>
				message.error({
					content: getApiErrorMsg(error),
				})
			)
			.finally(() => setSaving(false));
	};

	return (
		<>
			<SectionInfo
				heading="Tell us about your organisation"
				subHeading="Please provide your organisation information to be used across all communications on the platform"
			/>
			<Form
				name="sign-up-form"
				requiredMark={false}
				form={form}
				onFinish={onSubmit}
				disabled={saving}
				labelCol={{ span: 24 }}
				validateTrigger={["onBlur", "onChange"]}
				className={styles["form"]}
			>
				<Form.Item
					label="Organisation Name"
					name="org_name"
					rules={[{ required: true, message: "Please input organisation’s name!" }]}
				>
					<Input
						placeholder="Enter your organisation’s name"
						disabled={!!user?.tenant.organization_name}
					/>
				</Form.Item>
				<Form.Item
					label="Headquarters country"
					name="country"
					rules={[
						{
							required: true,
							message: "Please select headquarters country!",
							whitespace: true,
						},
					]}
				>
					<Select
						placeholder="Select"
						options={countries?.map((country: ICountry) => ({
							label: country.name,
							value: country.name,
						}))}
						optionFilterProp="label"
					/>
				</Form.Item>
				<Form.Item
					label="Organisation size"
					name="size"
					rules={[
						{
							required: true,
							message: "Please select organisation size!",
							whitespace: true,
						},
					]}
				>
					<Select placeholder="Select" options={organisationSizes} showSearch={false} />
				</Form.Item>
				<Form.Item
					label="Sector"
					name="department_id"
					rules={[
						{
							required: true,
							message: "Please select your sector!",
							whitespace: true,
						},
					]}
				>
					<Select
						loading={!departments}
						placeholder="Select"
						optionFilterProp="label"
						options={departments?.map((dep) => ({
							label: dep.name,
							value: dep.id,
						}))}
					/>
				</Form.Item>
				<Form.Item>
					<CustomButton
						type="primary"
						className={styles["submit-btn"]}
						htmlType="submit"
						loading={saving}
					>
						Next
					</CustomButton>
				</Form.Item>
			</Form>
		</>
	);
};

export default SecondStepForm;
