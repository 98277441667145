import { Breadcrumb, Divider } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BackArrowIcon from "../../assets/images/arrow-left-long.svg";
import CheckIcon from "../../assets/images/check.svg";
import { useScreenWidthHeight } from "../../hooks";
import { isNotEmptyArray } from "../../utils/type-util";

import styles from "./header.module.scss";

export interface HeaderBreadcrumbsProps {
  name: string;
  route?: string;
}

export interface HeaderStepsProps {
  title: string | React.ReactNode;
  completed?: boolean;
  disabled?: boolean;
}

interface HeaderProps {
  backIcon?: boolean;
  backUrl?: string;
  steps?: HeaderStepsProps[];
  breadcrumbs?: HeaderBreadcrumbsProps[];
  rightSideContent?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  getHeaderHeight?: (newHeight: number) => void;
  wrapperStyle?: React.CSSProperties;
  customBackIcon?: React.ReactNode;
  titleReverse?: boolean;
  clipBorder?: boolean;
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  const {
    backIcon,
    backUrl,
    steps,
    breadcrumbs,
    rightSideContent,
    title,
    getHeaderHeight,
    wrapperStyle,
    subtitle,
    customBackIcon,
    titleReverse = false,
    clipBorder = false,
  } = props;

  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { contentHeight, contentWidth } = useScreenWidthHeight();

  useEffect(() => {
    if (getHeaderHeight) {
      getHeaderHeight(headerHeight);
    }
  }, [headerHeight]);

  useEffect(() => {
    setHeaderHeight(headerRef?.current?.offsetHeight ?? 0);
  }, [contentHeight, contentWidth, props]);

  const onBreadcrumbClick = (
    e: React.MouseEvent<HTMLElement>,
    route: string
  ) => {
    e.preventDefault();
    navigate(route);
  };

  return (
    <div
      className={`${styles["wrapper"]} ${
        clipBorder ? styles["clip-border"] : ""
      }`}
      style={wrapperStyle}
      ref={headerRef}
    >
      <div className="d-flex" style={{ gap: "15px", alignItems: "center" }}>
        {(backIcon || backUrl) && (
          <div
            className="cursor-pointer"
            onClick={() => (backUrl ? navigate(backUrl) : navigate(-1))}
          >
            {customBackIcon ? (
              <>{customBackIcon}</>
            ) : (
              <img src={BackArrowIcon} alt="back" />
            )}
          </div>
        )}
        {title && (
          <div className={styles["title-wrapper"]}>
            <div
              className={`${styles["title"]} ${
                titleReverse ? styles["title-reverse"] : ""
              }`}
            >
              {title}
            </div>
            {subtitle && <div className={styles["subtitle"]}>{subtitle}</div>}
          </div>
        )}
        {isNotEmptyArray(breadcrumbs) && (
          <Breadcrumb separator=">">
            {breadcrumbs?.map((el, elIndex) => {
              if (el.route) {
                return (
                  <Breadcrumb.Item
                    key={el.name + "-" + elIndex}
                    className={styles["name-el"]}
                    onClick={(e) => onBreadcrumbClick(e, el.route ?? "")}
                    href={el.route}
                  >
                    {el.name}
                  </Breadcrumb.Item>
                );
              }
              return (
                <Breadcrumb.Item
                  key={el.name + "-" + elIndex}
                  className={styles["name-el"]}
                >
                  {el.name}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        )}
        {isNotEmptyArray(steps) &&
          steps?.map((step, stepIndex) => (
            <div
              key={step.title + "-" + stepIndex}
              className={`${styles["step-el"]} ${
                step.disabled ? styles["step-el-disabled"] : ""
              }`}
            >
              <div
                className={`${styles["step-el-icon-div"]} ${
                  step.completed ? styles["step-el-completed"] : ""
                }`}
              >
                {step.completed ? (
                  <img
                    src={CheckIcon}
                    className={styles["step-el-icon"]}
                    alt="check_icon"
                  />
                ) : (
                  stepIndex + 1
                )}
              </div>
              <div>{step.title}</div>
              {stepIndex < steps.length - 1 && (
                <Divider
                  style={{
                    width: "40px",
                    minWidth: "40px",
                    color: "#0B0B0B33",
                  }}
                />
              )}
            </div>
          ))}
      </div>
      {rightSideContent}
    </div>
  );
};

export default Header;
