import { useEffect, useState } from "react";
import { PolicyType } from "../constants/role-permissions";
import { api } from "../api";

import { useCurrentUser } from "./use-current-user";

const useAllPolicies = () => {
	const { user } = useCurrentUser();
	const [policies, setPolicies] = useState<PolicyType[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		api
			.get({
				path: `/tiers`,
				service: "auth",
			})
			.then((res) => {
				if (res.data?.data?.tier?.subscriptions?.length) {
					setPolicies(
						res.data.data.tier.subscriptions.map((sub: string) => ({
							name: sub
								.split("_")
								.join(" ")
								.toLowerCase()
								.replace(/(?:^|\s)\S/g, function (char) {
									return char.toUpperCase();
								}),
							key: sub,
						}))
					);
				}
			})
			.finally(() => setIsLoading(false));
	}, []);

	return { policies, isLoading };
};

export { useAllPolicies };
