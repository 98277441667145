import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as PopperIcon } from "../../../assets/images/popperIcon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/images/cross-icon-black.svg";
import CustomButton from "../../../components/button";
import { ReactComponent as HealthIcon } from "../../../assets/images/health-watch.svg";

import styles from "./popup.module.scss";
interface IProps {
	connectedWithWatch?: boolean;
	onClick?: () => void;
}

const PopUp = ({ connectedWithWatch, onClick }: IProps) => {
	// const [cancel, setCancel] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const authParam = searchParams.get("auth");

	// useEffect(() => {
	// 	const isPopupHidden = localStorage.getItem("popupHidden");
	// 	if (isPopupHidden === "true") {
	// 		setCancel(true);
	// 	}

	// }, []);

	const handleCancel = () => {
		// setCancel(true);
		// if (connectedWithWatch) {
		// 	localStorage.setItem("popupHidden", "true");
		// }
		if (searchParams.has("auth")) {
			searchParams.delete("auth");
			setSearchParams(searchParams);
		}
	};
	// if (cancel) {
	// 	return null;
	// }
	
	return (
		<>
			{connectedWithWatch && authParam && (
				<div className={styles["wrapper"]}>
					<div className={styles["content-icon"]}>
						<PopperIcon />
						<div className={styles["content"]}>
							<p className={styles["title"]}>You’re All Set!</p>
							<p className={styles["subtitle"]}>
								You've successfully connected your fitness tracker!
							</p>
						</div>
					</div>
					<CrossIcon className={styles["cross-icon"]} onClick={handleCancel} />
				</div>
			)}
			{((!connectedWithWatch && !!authParam) || !connectedWithWatch) && (
				<div className={styles["connect"]}>
					<div>
						<p className={styles["connect-heading"]}>Welcome to Your Health Journey!</p>
						<p className={styles["connect-subheading"]}>
							Connect your fitness tracker to unlock personalized health insights, set achievable
							fitness goals, and track your progress seamlessly
						</p>
						<CustomButton type="primary" onClick={onClick}>
							Get Started
						</CustomButton>
					</div>
					<HealthIcon />
				</div>
			)}
		</>
	);
};
export default PopUp;
