import { MenuProps, Dropdown, Flex } from "antd";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/logo-title-black.svg";
import BellIcon from "../../assets/images/bell-icon.svg";
import { ReactComponent as Clock } from "../../assets/images/clock 1.svg";
import { useAttendance, useCurrentUser, useRolePermission } from "../../hooks";
import { authService } from "../../auth";
import SubmitFeedback from "../submit-feedback";
import CurrentTime from "../../components/current-time";
import CustomButton from "../button";

import AvatarIcon from "../avatar-icon";

import styles from "./privateHeader.module.scss";

const PrivateHeader = () => {
	const navigate = useNavigate();
	const { user } = useCurrentUser();
	const { canCreate } = useRolePermission("ATTENDANCE");
	const { clockInAndOut, isClockInAndOutLoading, isClockedIn } = useAttendance();

	const items: MenuProps["items"] = [
		...(user?.is_super
			? []
			: [
					{
						label: <p className={styles["logout"]}>Profile</p>,
						key: "0",
						onClick: () => navigate("/profile-settings"),
					},
				]),
		{
			label: <p className={styles["logout"]}>Logout</p>,
			key: "1",
			onClick: authService.logout,
		},
	];

	return (
		<div className={styles["wrapper"]} id="header">
			<img src={Logo} alt="autoscal-logo" className={styles["logo-autoscal"]} />

			<div className={styles["right-menu"]}>
				{/* Feedback */}
				<SubmitFeedback />

				{/* Clock-In/Clock Out */}
				<>
					{canCreate && (
						<Flex align="stretch">
							<Flex align="center" justify="center" className={styles["time"]}>
								<CurrentTime format="hh:mm a" type="seconds" />
							</Flex>
							<CustomButton
								type={"primary"}
								danger={isClockedIn}
								icon={<Clock />}
								onClick={clockInAndOut}
								loading={isClockInAndOutLoading}
								style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: 8 }}
								size="middle"
							>
								{isClockedIn ? "Clock-Out" : "Clock-In"}
							</CustomButton>
						</Flex>
					)}
				</>

				{/* Notification Bell */}
				<div className={styles["bell-icon"]}>
					<img src={BellIcon} alt="bell-icon" />
					{/* <span className={styles["bell-circle"]}></span> */}
				</div>

				{/* <img src={HelpIcon} alt="help-icon" /> */}

				{/* <img src={SettingsIcon} alt="setting-icon" /> */}

				<Dropdown menu={{ items }} trigger={["click"]}>
					<a onClick={(e) => e.preventDefault()}>
						<AvatarIcon
							firstName={user?.first_name || ""}
							lastName={user?.last_name || ""}
							color={user?.rgb_icon || ""}
							contentClassName={styles["profile-icon"]}
							profilePic={user?.profile_picture ?? undefined}
						/>
					</a>
				</Dropdown>
			</div>
		</div>
	);
};

export default PrivateHeader;
