import { Input as AntdInput, InputProps } from "antd";

import styles from "../input.module.scss";

interface CustomInputProps extends InputProps {
  className?: string;
  wrapperStyle?: React.CSSProperties;
}

export const PasswordInput = ({
  className,
  wrapperStyle,
  ...rest
}: CustomInputProps) => {
  return (
    <div className={styles["wrapper"]} style={wrapperStyle}>
      <AntdInput.Password
        className={`${styles["input"]} ${className || ""}`}
        {...rest}
      />
    </div>
  );
};
