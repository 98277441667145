import { useState } from "react";
import { Empty, Form, message } from "antd";

import { api } from "../../../api";
import { IIdNamePair } from "../../../utils/common-interfaces";
import { Input, Select } from "../";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";
import CustomButton from "../../button";
import Modal from "../../modal";

import styles from "./addSkills.module.scss";

interface IAddSkillsProps {
  options: Array<{
    id: string;
    name: string;
  }>;
  onSearch: (val: string) => void;
  label: string;
  name: string | (string | number)[];
  isRequired?: boolean;
  requiredMessage?: string;
  placeholder?: string;
  canAdd?: boolean;
  apiUrl?: string;
  extraFormValues?: { [key: string]: any };
  isMultiple?: boolean;
  apiName?: string;
}

const AddSkills = (props: IAddSkillsProps) => {
  const {
    isMultiple = true,
    options,
    onSearch,
    label,
    name,
    isRequired = false,
    requiredMessage = "Please input skills!",
    placeholder,
    canAdd = true,
    apiUrl,
    apiName,
    extraFormValues,
  } = props;

  const form = Form.useFormInstance();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState("");

  const getFormData = (value: any) => {
    if (apiName) {
      return {
        ...(extraFormValues || {}),
        university_name: value,
      };
    } else
      return {
        ...(extraFormValues || {}),
        name: value,
      };
  };

  const handleAddItem = () => {
    form.setFieldValue("newSkillItem", searchedValue || "");
    setIsOpen(true);
  };

  const handleOnSearch = (val: string) => {
    setSearchedValue(val);
    onSearch(val);
  };

  const handleSubmit = (val: string) => {
    if (val?.trim()) {
      setIsLoading(true);

      api
        .post({
          path: apiUrl || "/skills",
          service: "job",
          formdata: getFormData(val),
        })
        .then((response) => {
          const prevValues = form.getFieldValue(name) || [];
          if (isMultiple) {
            form.setFieldValue(name, [
              ...prevValues,
              response?.data?.data?.name,
            ]);
          } else {
            form.setFieldValue(name, response?.data?.data?.name);
          }
          form.validateFields([name]);
          setIsOpen(false);
          setSearchedValue("");
          message.success({
            content: getApiResponseMsg(response),
            key: "success skill",
            duration: 2,
          });
        })
        .catch((error) => {
          message.error({
            content: getApiErrorMsg(error),
            key: "error skill",
            duration: 2,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      form.validateFields(["newSkillItem"]);
    }
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setSearchedValue("");
  };

  const handleModalSubmit = () => {
    handleSubmit(form.getFieldValue("newSkillItem"));
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      setSearchedValue("");
      onSearch(""); 
    }
  };
 
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: isRequired,
            message: requiredMessage,
          },
        ]}
      >
        <Select
          mode={isMultiple ? "multiple" : undefined}
          placeholder={
            placeholder || `Search ${canAdd ? "and add " : ""}your ${label}`
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              {canAdd && (
                <span
                  onClick={handleAddItem}
                  className={`${styles["add-item"]} cursor-pointer `}
                >
                  {`Add ${label}`}
                </span>
              )}
            </>
          )}
          options={
            options?.map((el: IIdNamePair) => ({
              value: el.name,
              label: el.name,
            })) || []
          }
          searchValue={searchedValue}
          onSearch={handleOnSearch}
          onFocus={() => setSearchedValue("")}
          onBlur={() => setSearchedValue(searchedValue)}
          popupClassName={styles["select-dropdown"]}
          notFoundContent={
            canAdd ? <></> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
          onChange={() => setSearchedValue("")} 
          onDropdownVisibleChange={handleDropdownVisibleChange}
         
        />
      </Form.Item>

      <Modal
        title={`Add ${label}`}
        isOpen={isOpen}
        destroyOnClose
        width="600px"
        zIndex={9999}
        onCancel={handleModalClose}
        bodyStyle={{ paddingBottom: 0 }}
        footer={
          <div className={styles["button-wrapper"]}>
            <CustomButton type="default" onClick={handleModalClose}>
              Cancel
            </CustomButton>
            <CustomButton
              htmlType="submit"
              danger
              loading={isLoading}
              onClick={handleModalSubmit}
            >
              Submit
            </CustomButton>
          </div>
        }
      >
        <Form.Item
          name="newSkillItem"
          rules={[
            {
              required: true,
              message: `Please enter new ${label}`,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={`Enter ${label}`}
            onPressEnter={handleModalSubmit}
            autoFocus
          />
        </Form.Item>
      </Modal>
    </>
  );
};

export { AddSkills };
