import { Divider } from "antd";

import styles from "./healthInsights.module.scss";

interface IProps {
	title: string;
	description: string;
	tracking: { subtitle: string; content: string }[];
	riskSigns: { subtitle: string; content: string }[];
	tips: { subtitle: string; content: string }[];
	benefits: { subtitle: string; content: string }[];
	footer: string;
}

const HealthDetails = ({ insight }: { insight: IProps }) => {
	return (
		<>
			<div className={styles["wrapper-top"]}>
				<p className={styles["title"]}>Description</p>
				<p className={styles["content"]}>{insight.description}</p>
				<p className={styles["title"]}>Tracking</p>
				<ul className={styles["content"]}>
					{insight.tracking.map((item, index) => (
						<li key={index} className={styles["pointers"]}>
							<span className={styles["subtitle"]}>{item.subtitle}: </span>
							{item.content}
						</li>
					))}
				</ul>
				<p className={styles["title"]}>Risk Signs</p>
				<ul className={styles["content"]}>
					{insight.riskSigns.map((item, index) => (
						<li key={index} className={styles["pointers"]}>
							<span className={styles["subtitle"]}>{item.subtitle}: </span>
							{item.content}
						</li>
					))}
				</ul>
			</div>
			<Divider />
			<div className={styles["wrapper-content"]}>
				<p className={styles["title"]}>Tips to Improve {insight.title}</p>
				<ul className={styles["content"]}>
					{insight.tips.map((item, index) => (
						<li key={index} className={styles["pointers"]}>
							<span className={styles["subtitle"]}>{item.subtitle}: </span>
							{item.content}
						</li>
					))}
				</ul>
				<p className={styles["title"]}>Benefits of Good {insight.title}</p>
				<ul className={styles["content"]}>
					{insight.benefits.map((item, index) => (
						<li key={index} className={styles["pointers"]}>
							<span className={styles["subtitle"]}>{item.subtitle}: </span>
							{item.content}
						</li>
					))}
				</ul>
			</div>
			<Divider />
			<div className={styles["wrapper-bottom"]}>
				<p className={styles["title"]}>{insight.footer}</p>
			</div>
		</>
	);
};
export default HealthDetails;
