import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { api } from "../../api";
import { useQuery } from "../../hooks";
import AuthLayout from "../../components/layouts/auth-layout";
import { getApiErrorMsg, getApiResponseMsg } from "../../utils/object-util";
import { Input } from "../../components/form-fields";
import PasswordPolicies from "../../components/password-policies";
import CustomButton from "../../components/button";

import styles from "./signup.module.scss";

interface SignUpForm {
	first_name: string;
	last_name: string;
	email: string;
	invitedBy_Name: string;
}

const SignUp = () => {
	const { query } = useQuery();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const token = query.get("token");

	const [loadingUser, setLoadingUser] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [verifyData, setVerifyData] = useState<SignUpForm>({
		first_name: "",
		last_name: "",
		email: "",
		invitedBy_Name: "",
	});

	const verifyUser = () => {
		if (token) {
			setLoadingUser(true);
			api
				.get({
					path: `/users/verify-token`,
					service: "auth",
					params: { token },
				})
				.then((response) => {
					setVerifyData(response.data.data);
					form.setFieldsValue(response.data.data);
				})
				.catch((err) => {
					message.error({
						content: getApiErrorMsg(err),
						key: "verify-user",
						duration: 3,
					});
					navigate("/login", { replace: true });
				})
				.finally(() => setLoadingUser(false));
		} else {
			navigate("/login", { replace: true });
		}
	};

	useEffect(() => {
		verifyUser();
	}, [token]);

	const handleSignup = (values: any) => {
		setSubmitting(true);
		api
			.put({
				path: "/users/signup",
				service: "auth",
				formdata: {
					first_name: values.first_name,
					last_name: values.last_name,
					password: values.password,
				},
				params: token ? { token } : {},
			})
			.then((response) => {
				message.success({
					content: getApiResponseMsg(response),
					key: "signup-user",
					duration: 3,
				});
				navigate("/login", { replace: true });
			})
			.catch((err) => {
				message.error({
					content: getApiErrorMsg(err),
					key: "signup-user",
					duration: 3,
				});
			})
			.finally(() => setSubmitting(false));
	};

	return (
		<AuthLayout
			loading={loadingUser}
			leftContent={{
				title: (
					<>
						Embrace the future <br /> of work with Autoscal
					</>
				),
				list: ["Made for effortless HR management"],
			}}
		>
			<div className={styles["form-container"]}>
				<h1 className={styles["title"]}>Create your account</h1>
				<Form
					name="login-form"
					form={form}
					initialValues={verifyData}
					disabled={submitting}
					onFinish={handleSignup}
					autoComplete="off"
					requiredMark={false}
					labelCol={{ span: 24 }}
					validateTrigger={["onBlur", "onChange"]}
				>
					<div className={styles["name-inputs"]}>
						<Form.Item
							label="First name"
							name="first_name"
							rules={[
								{
									required: true,
									message: "First name is required!",
								},
								{ whitespace: true, message: "First name is required!" },
							]}
						>
							<Input placeholder="Enter First Name" />
						</Form.Item>
						<Form.Item
							label="Last name"
							name="last_name"
							rules={[
								{ required: true, message: "Last name is required!" },
								{ whitespace: true, message: "Last name is required!" },
							]}
						>
							<Input placeholder="Enter Last Name" />
						</Form.Item>
					</div>
					<Form.Item label="Email" name="email">
						<Input disabled placeholder="Enter Email Address" />
					</Form.Item>

					<PasswordPolicies isConfirmPassword />

					<CustomButton
						loading={submitting}
						type="primary"
						htmlType="submit"
						className={styles["form-submit-btn"]}
					>
						Create account
					</CustomButton>
					<p className={styles["have-an-account"]}>
						Already have an account? &nbsp;
						<Link className={styles["back-to-login"]} to="/login">
							Login
						</Link>
					</p>
				</Form>
			</div>
		</AuthLayout>
	);
};

export default SignUp;
