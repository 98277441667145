import GoalDetails from "./goal-details";
import { api } from "../../../api";
import { useEffect, useState } from "react";
import { message } from "antd";
import { getApiErrorMsg } from "../../../utils/object-util";
import Empty from "../../../components/empty";
import Spinner from "../../../components/spinner";

interface goalsProp {
	id?: string;
	title: string;
	onClose?: () => void;
}

interface Goal {
	title: string;
	description: string;
	reasons: string[];
	trackingInfo: string;
	trackingButtonLabel: string;
	trackingCancelLabel: string;
	id?: string;
	amount?: number;
	type: string;
}

const goalsData: Goal[] = [
	{
		title: "Sleep Goals",
		description:
			"A good night's sleep is the cornerstone of a healthy, happy life. Ready to wake up refreshed and energized every day? Let’s set your sleep goals and start your journey to better rest!",
		reasons: [
			"Boosts Mood: Say goodbye to crankiness and hello to positivity!",
			"Improves Focus: Sharpen your mind and ace your day.",
			"Enhances Health: Strengthen your immune system and overall well-being.",
		],
		trackingInfo:
			"Your fitness tracker will automatically sync your sleep data. Make sure your tracker is connected!",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Sleep",
	},
	{
		title: "Activity Goals",
		description:
			"Get ready to move your body and feel fantastic! Setting activity goals will boost your energy and keep you strong and healthy.",
		reasons: [
			"Energy Boost: Feel more energized throughout your day.",
			"Strengthens Muscles: Get stronger and more toned.",
			"Improves Mood: Exercise releases those feel-good endorphins!",
		],
		trackingInfo:
			"Log your exercise sessions to keep track of your progress. Your fitness tracker will automatically sync your data.",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Activity",
	},
	{
		title: "Distance Goals",
		description:
			"Get ready to challenge yourself and explore new distances. Setting distance goals will keep you on your feet and push your endurance to new levels",
		reasons: [
			"Increased Stamina: Build endurance with each step you take.",
			"Cardio Health: Keep your heart healthy by staying active.",
			"Weight Management: Burn calories and maintain a healthy weight.",
		],
		trackingInfo:"Your wearable device will automatically track the distance you walk, run, or cycle, giving you real-time feedback on your progress.",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Distance",
	},
	{
		title: "Water Goals",
		description:
			"Water is essential for health! Set your water intake goals and stay hydrated throughout the day.",
		reasons: [
			"Hydrates Your Body: Water is essential for all bodily functions.",
			"Improves Skin Health: Stay hydrated for glowing skin.",
			"Regulates Body Temperature: Helps maintain a stable body temperature.",
		],
		trackingInfo:
			"Track your daily water intake to ensure you meet your hydration goals. Your fitness tracker will automatically sync your data.",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Water",
	},
];

const Goals = ({ title, onClose, id }: goalsProp) => {
	const [data, setData] = useState<Goal | null>();
	const [loading,setIsLoading]=useState(false)

	useEffect(() => {
		setIsLoading(true)
		api
			.get({
				path: `/goals/${id}`,
				service: "job",
			})
			.then((response) => {
				setData(response.data.data.amount);
				if (response?.data?.data && response?.data?.data?.amount !== undefined) {
					const matchingGoal = goalsData.find((goal) => goal.type === response.data.data.type);

					if (matchingGoal) {
						setData({
							...matchingGoal,
							id: id ?? "",
							amount: response.data.data.amount ?? 0,
						});
					}
				}
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
				setIsLoading(false)
			}).finally(() => {
				setIsLoading(false)
			})
	}, [title, id]);

	return (
		<div>
			{loading ? (
				<Spinner />
			) : data && data?.id ? (
				<GoalDetails goal={data} onClose={onClose} />
			) : (
				<div><Empty/></div>
			)}
		</div>
	);
};
export default Goals;
