import { useState } from "react";
import { Form, message } from "antd";
import dayjs from "dayjs";
import { isValidPhoneNumber } from "react-phone-number-input";

import { api } from "../../api";
import { useCurrentUser, useCountryList } from "../../hooks";
import { getApiErrorMsg, getApiResponseMsg } from "../../utils/object-util";
import { ICountry } from "../../utils/common-interfaces";
import CustomButton from "../../components/button";
import { DatePicker, Input, Select, MobileInput } from "../../components/form-fields";
import SectionInfo from "./section-info";

import styles from "./tenant-sign-up.module.scss";

interface IProps {
	onSubmitCallback: () => void;
}

const ThirdStepForm = ({ onSubmitCallback }: IProps) => {
	const { user } = useCurrentUser();
	const { countries } = useCountryList();
	const [form] = Form.useForm();

	const [saving, setSaving] = useState(false);

	const activateTenant = async () =>
		api
			.put({
				path: `/tenants`,
				formdata: {
					status: "Active",
				},
				service: "auth",
			})
			.then((res) => {
				message.success({
					content: getApiResponseMsg(res),
				});
				onSubmitCallback();
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
				});
			});

	const onSubmit = (values: any) => {
		setSaving(true);
		const formData = {
			address: [
				{
					name: "personal",
					country: values.country,
					address_line: values.country,
					city: null,
					state: null,
				},
			],
			first_name: values.first_name,
			last_name: values.last_name,
			date_of_birth: dayjs(values.date_of_birth).format("DD-MM-YYYY"),
			mobile_no: values.mobile_no,
		};

		api
			.put({
				path: `/team-members/${user?.team_member_id}`,
				formdata: formData,
				service: "job",
			})
			.then(async () => await activateTenant())
			.catch((error) =>
				message.error({
					content: getApiErrorMsg(error),
				})
			)
			.finally(() => setSaving(false));
	};

	return (
		<>
			<SectionInfo
				heading="Create your personal profile"
				subHeading="These details will be used to complete your profile"
			/>
			<Form
				name="sign-up-form"
				form={form}
				requiredMark={false}
				onFinish={onSubmit}
				disabled={saving}
				labelCol={{ span: 24 }}
				validateTrigger={["onBlur", "onChange"]}
				className={styles["form"]}
			>
				<Form.Item
					label="First name"
					name="first_name"
					rules={[{ required: true, message: "Please input first name!" }]}
				>
					<Input placeholder="Enter your first name" />
				</Form.Item>
				<Form.Item
					label="Last name"
					name="last_name"
					rules={[{ required: true, message: "Please input last name!" }]}
				>
					<Input placeholder="Enter your last name" />
				</Form.Item>
				<Form.Item
					label="I’m a citizen of"
					name={"country"}
					rules={[
						{
							required: true,
							message: "Please select residing country!",
							whitespace: true,
						},
					]}
				>
					<Select
						placeholder="Select"
						allowClear
						options={countries?.map((country: ICountry) => ({
							label: country.name,
							value: country.name,
						}))}
					/>
				</Form.Item>
				<Form.Item
					label="Date of birth"
					name="date_of_birth"
					rules={[
						{
							required: true,
							message: "Please enter your date of birth!",
						},
					]}
				>
					<DatePicker
						format="DD-MM-YYYY"
						placement="bottomRight"
						popupClassName={styles["date-picker-popup-custom"]}
						disabledDate={(current) => current && current > dayjs().endOf("day")}
					/>
				</Form.Item>
				<Form.Item
					label="Phone number"
					name={"mobile_no"}
					rules={[
						{
							required: true,
							message: "Please input mobile number!",
						},
						() => ({
							validator(_, value) {
								if (
									value &&
									!isValidPhoneNumber(`${value}`?.startsWith("+") ? value : `+${value}`)
								) {
									return Promise.reject(new Error("Invalid mobile number!"));
								} else {
									return Promise.resolve();
								}
							},
						}),
					]}
				>
					<MobileInput />
				</Form.Item>
				<Form.Item>
					<CustomButton
						type="primary"
						className={styles["submit-btn"]}
						htmlType="submit"
						loading={saving}
					>
						Complete Sign Up
					</CustomButton>
				</Form.Item>
			</Form>
		</>
	);
};

export default ThirdStepForm;
