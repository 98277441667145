import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";

import styles from "./textArea.module.scss";

interface CustomTextAreaProps extends TextAreaProps {
  wrapperStyle?: React.CSSProperties;
}

export const TextArea = (props: CustomTextAreaProps) => {
  const { wrapperStyle, ...rest } = props;

  return (
    <div className={styles["wrapper"]} style={wrapperStyle}>
      <Input.TextArea {...rest} />
    </div>
  );
};
