import { useRef } from "react";
import { AutoComplete as AntdAutoComplete, AutoCompleteProps } from "antd";

import styles from "./autoComplete.module.scss";

export const AutoComplete = ({ className, ...rest }: AutoCompleteProps) => {
  const ref = useRef<any>();

  return (
    <div className={styles["wrapper"]} ref={ref}>
      <AntdAutoComplete
        className={`${styles["dropdown-wrapper"]} ${className || ""}`}
        getPopupContainer={() => ref.current}
        {...rest}
      />
    </div>
  );
};
