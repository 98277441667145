import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUser } from "../../hooks";
import DefaultPlaceHolderScreen from "../../components/default-placeholder-screen";

const DefaultPage = () => {
  const { user } = useCurrentUser();
  const { pathname } = useLocation();
  // const [matchedRoute, setMatchedRoute] = useState<routeObjectType>();

  useEffect(() => {
    // if (user.menu?.length) {
    //   const tempMatchedRouteKey = Object.keys(routes).find((routeKey) =>
    //     pathname.includes(routes[routeKey].route)
    //   );
    //   if (tempMatchedRouteKey) {
    //     setMatchedRoute(routes[tempMatchedRouteKey]);
    //   }
    // }
  }, [user?.menu, pathname]);

  return (
    <>
      <DefaultPlaceHolderScreen />
    </>
  );
};

export default DefaultPage;
