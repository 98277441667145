import { useState } from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";

import { authService } from "../../auth";
import { ReactComponent as Alert } from "../../assets/images/alert-triangle.svg";
import AuthLayout from "../../components/layouts/auth-layout";
import { Input, Checkbox } from "../../components/form-fields";
import CustomButton from "../../components/button";

import styles from "./login.module.scss";

const Login = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleLogin = (values: { [key: string]: any }) => {
    setSubmitting(true);
    authService
      .login({
        email: values.email.toLowerCase(),
        password: values.password,
        remember_me: !!values.remember,
      })
      .then((response) => {
        if (response.data?.data) {
          window.location.reload();
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setError(err.response?.data?.message);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <AuthLayout
      leftContent={{
        title: <>Embrace the future <br/> of work with Autoscal</>,
        list: [
        "Made for effortless HR management",
        ],
      }}
    >
      <div className={styles["form-container"]}>
        <h1 className={styles["title"]}>Login </h1>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          disabled={submitting}
          onFinish={handleLogin}
          autoComplete="off"
          requiredMark={false}
          labelCol={{ span: 24 }}
          validateTrigger={["onBlur"]}
        >
          {/* Error Message */}
          {error && (
            <div className={styles["error-message"]}>
              <Alert className={styles["alert-image"]} />
              <p className={styles["alert-text"]}>{error}</p>
            </div>
          )}

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please input valid email!" },
            ]}
            style={{ marginBottom: "22px" }}
          >
            <Input placeholder="Enter Email Address" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password" }]}
            style={{ marginBottom: "12px" }}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>

          <div className={styles["under-input"]}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox checked={false}>
                <p className={styles["checkbox-title"]}>Remember me</p>
              </Checkbox>
            </Form.Item>

            <Link
              to="/forgot-password"
              className={styles["forgot-password"]}
              id="forgot-password-link"
            >
              Forgot password?
            </Link>
          </div>

          <CustomButton
            loading={submitting}
            type="primary"
            htmlType="submit"
            className={styles["form-submit-btn"]}
            id="loginButton"
          >
            Login
          </CustomButton>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
