import PhoneInput, {
  FeatureProps,
  Value,
  parsePhoneNumber,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
import Styles from "./mobileInput.module.scss";

interface IMobileInputProps extends FeatureProps<any> {
  value?: any;
  onChange?: (e: any) => void;
}

const MobileInput = ({ value, onChange, ...rest }: IMobileInputProps) => {
  const onCustomChange = (val: Value | undefined) => {
    if (onChange) {
      onChange({ target: { value: val as string } });
    }
  };

  const country =
    (value &&
      parsePhoneNumber(`${value}`?.startsWith("+") ? value : `+${value}`)
        ?.country) ||
    "IN";
  const phoneNumber =
    value &&
    parsePhoneNumber(`${value}`?.startsWith("+") ? value : `+${value}`)?.number;

  return (
    <PhoneInput
      international
      withCountryCallingCode
      countryCallingCodeEditable
      className={Styles.mobile}
      defaultCountry={country}
      value={phoneNumber ? phoneNumber : value}
      placeholder="Enter phone number"
      onChange={onCustomChange}
      {...rest}
    />
  );
};

export { MobileInput };
