import { Spin, SpinProps } from "antd";

import styles from "./spinner.module.scss";

interface IProps extends SpinProps {
	wrapperStyle?: React.CSSProperties;
}

const Spinner = ({ size, wrapperStyle, ...rest }: IProps) => {
	return (
		<div className={styles["spinner-wrapper"]} style={wrapperStyle}>
			<Spin size={size || "default"} {...rest} />
		</div>
	);
};

export default Spinner;
