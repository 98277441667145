import { useRef } from "react";
import { DatePicker as AntdDatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";

import { ReactComponent as NextIcon } from "../../../assets/images/chevron-right.svg";
import { ReactComponent as PrevIcon } from "../../../assets/images/chevron-left.svg";
import { ReactComponent as SuperNextIcon } from "../../../assets/images/chevrons-right.svg";
import { ReactComponent as SuperPrevIcon } from "../../../assets/images/chevrons-left.svg";

import styles from "./dataPicker.module.scss";

export const DatePicker = (props: DatePickerProps) => {
  const { value, placeholder, className, popupClassName, ...rest } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const val = typeof value === "string" ? dayjs(value) : value ?? undefined;
  return (
    <div className={styles["datepicker-wrapper"]} ref={wrapperRef}>
      <AntdDatePicker
        value={val}
        className={`${styles["datepicker"]} ${className || ""}`}
        popupClassName={`${styles["datepicker-popup"]} ${popupClassName || ""}`}
        placeholder={placeholder || "Select Date"}
        nextIcon={<NextIcon />}
        prevIcon={<PrevIcon />}
        superPrevIcon={<SuperPrevIcon />}
        superNextIcon={<SuperNextIcon />}
        getPopupContainer={(inner) => wrapperRef.current || inner}
        {...rest}
      />
    </div>
  );
};
