import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Pagination, Progress, Divider, message, Row, Col, Flex } from "antd";

import { api } from "../../api";
import { useCurrentUser } from "../../hooks";
import { ReactComponent as CustomIcon } from "../../assets/images/list-icon.svg";
import { ReactComponent as SleepIcon } from "../../assets/images/sleep-icon.svg";
import { ReactComponent as DropletIcon } from "../../assets/images/droplet.svg";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { ReactComponent as DropIcon } from "../../assets/images/drop.svg";
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron-right.svg";
import { BaseCard } from "../../components/base-card";
import CustomButton from "../../components/button";
import { getApiErrorMsg } from "../../utils/object-util";
import { goalStrokeColor } from "../../constants/index";
import Spinner from "../../components/spinner";
import HealthInsightCards from "./health-insight-cards";

import Section from "./section";
import GoalCard from "./goals-card";
import WeekActivity from "./week-activity";
import CustomDrawer from "./custom-drawer";
import Goals from "./goals";
import KnowMore from "./knowmore";
import WaterLog from "./waterlog";
import PopUp from "./pop-up";
import GetStarted from "./get-started";
import HealthInsights from "./health-insights";
import CustomizeToday from "./customizetoday";
import styles from "./health.module.scss";
import WaterLogCard from "./water-log-card";
import MyBadges from "./my-badges";
interface Badge {
	id: string;
	teamMemberId: string;
	badge_id: string;
	tenant_id: string;
	assigned_at: string;
}
interface BadgeEntry {
	id: string;
	type: string;
	tenant_id: string;
	badges: Badge[];
	count: number;
}
interface HealthData {
	healthMetrics: [];
	water_consumed: number;
	water_consumed_goal: number;
	weekly_activities: [
		{
			date: "";
			activity_log: [];
		},
	];
}

const units: { [key: string]: string } = {
	distance: "kms",
	time: "mins",
	steps: "",
	calories: "cal",
	sleep_duration: "",
	activity: "",
	floors: "",
	heart: "",
	active_zone_minutes: "",
};
interface Goal {
	id: string;
	type: string;
	amount: number;
	gemReward: number;
	teamMemberId: string;
	isStreakStarted: boolean;
	streak: number;
	isStreakBroken: boolean;
	streakBrokenAt: string | null;
	isCompleted: boolean;
	streakCompletedAt: string | null;
	created_at: string;
	updated_at: string;
}

const Health: React.FC = () => {
	const [currentDay, setCurrentDay] = useState(dayjs());
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [drawerContent, setDrawerContent] = useState<React.ReactNode>(null);
	const [drawerTitle, setDrawerTitle] = useState("");
	const [data, setData] = useState<HealthData>({} as HealthData);
	const [loading, setLoading] = useState(true);
	const [badgesArray, setBadgesArray] = useState<BadgeEntry[]>([]);
	const [fetchedGoals, setFetchedGoals] = useState<Goal[]>();
	const { user } = useCurrentUser();
	const goalProgress = 0;

	const healthCards = [
		{
			title: "Cardiovascular Fitness",
			content: "Measures the efficiency of your heart and lungs during activity",
		},
		{
			title: "Body Mass Index",
			content: "An indicator of body fat based on weight and height",
		},
		{
			title: "Blood Pressure",
			content: "The force of blood against the walls of your arteries",
		},
		{
			title: "Blood Glucose Levels",
			content: "Reflects how well your body manages blood sugar",
		},
		{
			title: "Cholesterol Levels",
			content: "The amount of cholesterol in your blood, important for heart health",
		},
		{
			title: "Vitamin D Levels",
			content: "Essential for bone health and immune function",
		},
		{
			title: "Iron Levels",
			content: "Crucial for red blood cell production and energy levels",
		},
		{
			title: "Sleep Quality",
			content: "Indicates how well you sleep and its impact on your health",
		},
	];

	const goals = [
		{
			key: "Sleep",
			title: "Sleep Goals",
			trackingMetric: "Daily Sleep Hours",
			progress: goalProgress,
			footerValue: 10,
			onClick: () =>
				openDrawer(
					<Goals
						title="Sleep Goals"
						onClose={closeDrawer}
						id={getGoalPropertyByType("Sleep", "id", "")}
					/>,
					"Sleep Goals"
				),
			IconComponent: SleepIcon,
		},
		{
			key: "Activity",
			title: "Activity Goals",
			trackingMetric: "Weekly Exercise Days",
			progress: goalProgress,
			footerValue: 10,
			onClick: () =>
				openDrawer(
					<Goals
						title="Activity Goals"
						onClose={closeDrawer}
						id={getGoalPropertyByType("Activity", "id", "")}
					/>,
					"Activity Goals"
				),
			IconComponent: DropletIcon,
		},
		{
			key: "Distance",
			title: "Distance Goals",
			trackingMetric: "Track the total distance covered",
			progress: goalProgress,
			footerValue: 10,
			onClick: () =>
				openDrawer(
					<Goals
						title="Distance Goals"
						onClose={closeDrawer}
						id={getGoalPropertyByType("Distance", "id", "")}
					/>,
					"Distance Goals"
				),
			IconComponent: HeartIcon,
		},
		{
			key: "Water",
			title: "Water Goals",
			trackingMetric: "Daily Water Intake",
			progress: goalProgress,
			footerValue: 10,
			onClick: () =>
				openDrawer(
					<Goals
						title="Water Goals"
						onClose={closeDrawer}
						id={getGoalPropertyByType("Water", "id", "")}
					/>,
					"Water Goals"
				),
			IconComponent: DropIcon,
		},
	];

	const openDrawer = (content: React.ReactNode, title: string) => {
		setDrawerContent(content);
		setDrawerTitle(title);
		setDrawerVisible(true);
	};

	const closeDrawer = () => {
		setDrawerVisible(false);
		setDrawerContent(null);
		setDrawerTitle("");
	};

	const getBadges = () => {
		api
			.get({
				path: "/fitbit-data/get-user-badges",
				service: "job",
			})
			.then((response) => {
				setBadgesArray(response.data.data);
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
			});
	};

	const getGoal = () => {
		api
			.post({
				path: "/goals/all",
				service: "job",
			})
			.then((response) => {
				setFetchedGoals(response.data.data);
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
			});
	};

	useEffect(() => {
		setLoading(true);
		api
			.post({
				path: "/fitbit-data/get-data",
				formdata: { date: currentDay.format("YYYY-MM-DD") },
				service: "job",
			})
			.then((resp) => {
				setData(resp.data.data);
			})
			.catch((err) => {
				message.error({
					content: err?.response?.data?.message,
					key: "error",
					duration: 3,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [currentDay]);

	useEffect(() => {
		getBadges();
		getGoal();
	}, []);

	const formatMetricKey = (key: string) => {
		return key
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	const handleSetData = (newData: any) => {
		const tempData = JSON.parse(JSON.stringify(data));
		tempData.healthMetrics = tempData.healthMetrics.map((metric: any) => {
			const key = metric.key;
			if (newData.hasOwnProperty(key)) {
				metric.active = newData[key];
			}
			return metric;
		});
		setData(tempData);
	};

	const formatter = new Intl.NumberFormat("en-US");

	const getGoalPropertyByType = (
		goalType: string,
		property: keyof Goal,
		defaultValue: any = null
	): any => {
		const goal = fetchedGoals?.find((goal: Goal) => goal.type === goalType);
		return goal ? goal[property] : defaultValue;
	};
	return loading ? (
		<Spinner />
	) : (
		<div className={styles["health-wrapper"]}>
			<PopUp
				connectedWithWatch={user?.sessions_data?.health?.fitbit_integrated}
				onClick={() => openDrawer(<GetStarted />, "Customize Today")}
			/>
			<div className={styles["goal-activity-container"]}>
				<div className={styles["container-wrapper"]}>
					<Section
						leftSideContent={
							<Flex gap={4} align="center">
								<p className={styles["day"]}>
									{currentDay.isSame(dayjs(), "day")
										? "Today"
										: currentDay.format("ddd, Do MMM YY")}
								</p>
								<Flex>
									<CustomButton
										icon={
											<ChevronLeft onClick={() => setCurrentDay(currentDay.subtract(1, "day"))} />
										}
										type="text"
									/>
									<CustomButton
										icon={<ChevronRight onClick={() => setCurrentDay(currentDay.add(1, "day"))} />}
										type="text"
										disabled={currentDay.isSame(dayjs(), "day")}
									/>
								</Flex>
							</Flex>
						}
						rightSideContent={
							<CustomButton
								type="link"
								onClick={() =>
									openDrawer(
										<CustomizeToday
											onClose={closeDrawer}
											data={data ?? undefined}
											setData={handleSetData}
										/>,
										"Customize Today"
									)
								}
								className={styles["custom-button"]}
							>
								<CustomIcon />
								Customize Today
							</CustomButton>
						}
						wrapperClassName={styles["header-wrapper"]}
					>
						<>
							{data?.healthMetrics
								?.filter((metric: any) => metric.active)
								.map((metric: any, index: any) => (
									<BaseCard key={metric.name} wrapperClassName={styles["card-wrapper"]}>
										<div>
											<p className={styles["card-text"]}>{formatMetricKey(metric.key)}</p>
											<p className={styles["card-score"]}>
												{formatter.format(metric.value)}{" "}
												{metric.goal > 0 ? (
													<span className={styles["progress-subtext"]}>
														{units[metric.key] || ""}
													</span>
												) : (
													""
												)}
											</p>
										</div>
										{metric?.value >= 0 ? (
											<Progress
												type="circle"
												percent={(metric.value / metric.goal) * 100}
												showInfo={false}
												strokeWidth={20}
												size={40}
												strokeColor={goalStrokeColor[index % goalStrokeColor.length]}
											/>
										) : null}
									</BaseCard>
								))}
						</>
					</Section>

					<Section
						childrenStyle={{ flex: 1 }}
						leftSideContent={
							<>
								<h4>30 Day Goals!</h4>
								<p className={styles["sub-heading"]}>
									Challenge yourself to achieve these goals for 30 days to boost your health and
									fitness!
								</p>
							</>
						}
						>
						<>
							{goals.map((goal, index) => (
								<GoalCard
									key={index}
									title={goal.title}
									trackingMetric={goal.trackingMetric}
									progress={getGoalPropertyByType(goal.key, "streak", 0)}
									footerValue={goal.footerValue}
									onClick={goal.onClick}
									IconComponent={goal.IconComponent}
									amount={getGoalPropertyByType(goal.key, "amount", 0)}
									data={fetchedGoals}
									isStreakStarted={getGoalPropertyByType(goal.key, "isStreakStarted", false)}
									streakDays={getGoalPropertyByType(goal.key, "streak", 0)}
									isStreakCompleted={getGoalPropertyByType(goal.key, "isCompleted", false)}
									getRewards={getGoalPropertyByType(goal.key, "gemReward", 0)}
								/>
							))}
						</>
					</Section>
				</div>

				<div className={styles["wrapper"]}>
					<WeekActivity currentDay={currentDay} weekly_activities={data?.weekly_activities ?? []} />
					{/* {typeof data?.water_consumed_goal !== "undefined" && data.water_consumed_goal > 0 && ( */}
					<WaterLogCard
						waterConsumed={data?.water_consumed ?? 0}
						waterConsumedGoal={data?.water_consumed_goal ?? 0}
						onClick={() => {
							openDrawer(
								<WaterLog
									waterConsumed={data?.water_consumed ?? 0}
									waterConsumedGoal={data?.water_consumed_goal ?? 0}
									onclose={(amount) => {
										closeDrawer();
										setData((prev) => {
											console.log(prev.water_consumed + amount);
											return {
												...prev,
												water_consumed: Number(prev.water_consumed) + Number(amount),
											};
										});
									}}
								/>,
								"Water Log"
							);
						}}
					/>
					{/* )} */}
				</div>
			</div>

			<Section
				leftSideContent={<h4>My Badges</h4>}
				rightSideContent={
					<CustomButton
						type="link"
						onClick={() => openDrawer(<KnowMore badgesArray={badgesArray} />, "Badges")}
						className={styles["custom-button"]}
					>
						Know More
					</CustomButton>
				}
			>
				<MyBadges badgesArray={badgesArray} />
			</Section>
			<Divider />
			<Section
				leftSideContent={<h4>Health Insights</h4>}
				childrenClassName={styles["health-insights-card"]}
			>
				<Row gutter={[16, 16]}>
					{healthCards.map((item, index) => (
						<Col key={index} span={6}>
							<HealthInsightCards
								title={item.title}
								content={item.content}
								onClick={() => openDrawer(<HealthInsights title={item.title} />, item.title)}
							/>
						</Col>
					))}
				</Row>
			</Section>
			<CustomDrawer
				visible={drawerVisible}
				title={drawerTitle}
				onClose={closeDrawer}
				content={drawerContent}
				className={styles["custom-drawer"]}
			/>
		</div>
	);
};
export default Health;
