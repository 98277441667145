import styles from "./baseCard.module.scss";

interface IProfileHeadingProps {
	heading: string;
	className?: string;
	style?: React.CSSProperties;
}

export const BaseCardHeading = ({ heading, style, className }: IProfileHeadingProps) => {
	return (
		<p className={`${styles["base-card-title"]} ${className ?? ""}`} style={style || {}}>
			{heading}
		</p>
	);
};
