import { Drawer as AntdDrawer, DrawerProps } from "antd";

import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";

import styles from "./new.module.scss";

interface IProps extends DrawerProps {
	subtitle?: string | React.ReactNode;
	extra?: React.ReactNode;
	headerContent?: React.ReactNode;
	headerClassName?: string;
	headerStyle?: React.CSSProperties;
	titleStyle?: React.CSSProperties;
	subTitleStyle?: React.CSSProperties;
	titleContClassName?: string;
}

const Drawer = ({
	title,
	subtitle,
	children,
	className,
	extra,
	headerContent,
	headerClassName,
	headerStyle,
	titleStyle,
	subTitleStyle,
	titleContClassName,
	placement = "right",
	width = "568",
	...rest
}: IProps) => {
	return (
		<AntdDrawer
			className={`${styles["drawer"]} ${className ?? ""}`}
			placement={placement}
			width={width}
			title={
				<div className={`${styles["header"]} ${headerClassName ?? ""}`} style={headerStyle}>
					<div
						className={`${styles["title-cont"]} ${titleContClassName ?? ""}`}
						style={{ marginBottom: headerContent ? 10 : 0 }}
					>
						<div>
							<div className={styles["title"]} style={titleStyle}>
								{title}
							</div>
							{subtitle && (
								<div
									className={`${styles["subtitle"]}`}
									style={subTitleStyle}
								>
									{subtitle}
								</div>
							)}
						</div>
						<div className={styles["extra-cont"]}>
							{extra}
							<CloseIcon onClick={rest.onClose} id="drawer-close-icon" role="button" />
						</div>
					</div>
					{headerContent && headerContent}
				</div>
			}
			{...rest}
		>
			{children}
		</AntdDrawer>
	);
};

export default Drawer;
