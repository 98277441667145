import { useState } from "react";

import { api } from "../api";
import { IPosition } from "../utils/common-interfaces";

const useAllPositions = () => {
	const [data, setData] = useState<IPosition[]>();
	const [isLoading, setIsLoading] = useState(true);

	const fetchData = (value?: string, page = "1", limit = "100000") => {
		setIsLoading(true);
		return api
			.post({
				path: `/positions/all`,
				service: "job",
				formdata: {
					page,
					limit,
					...(value ? { position_levels: [value] } : {}),
				},
			})
			.then((response) => setData(response?.data?.data))
			.finally(() => setIsLoading(false));
	};

	return { data, isLoading, fetchData };
};

export { useAllPositions };
