import { Input as AntdInput, InputProps } from "antd";

import { PasswordInput } from "./password";

import styles from "./input.module.scss";

interface CustomInputProps extends InputProps {
  className?: string;
  wrapperStyle?: React.CSSProperties;
  select?: boolean;
}

const Input = ({ className, wrapperStyle, ...rest }: CustomInputProps) => {
  return (
    <div className={styles["wrapper"]} style={wrapperStyle}>
      <AntdInput
        className={`${styles["input"]} ${className || ""}`}
        {...rest}
      />
    </div>
  );
};

Input.Password = PasswordInput;

export { Input };
