import styles from "./section-info.module.scss";

type Props = {
  heading: string;
  subHeading?: string;
};

const SectionInfo = ({ heading, subHeading }: Props) => {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["heading"]}>{heading}</div>
      {subHeading && <div className={styles["subHeading"]}>{subHeading}</div>}
    </div>
  );
};

export default SectionInfo;
